import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/common/base/button";
import FormInput from "components/common/base/FormInput";
import { paymentSchema } from "helpers/schema";
export const PayNowForm = ({ onSubmit, defaultValues, isPaymentLoading }) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(paymentSchema),
    defaultValues,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <FormInput
              {...register("phoneNumber")}
              type="number"
              placeholder="Enter Phone Number"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              error={errors?.phoneNumber && errors.phoneNumber.message}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          name="idNumber"
          control={control}
          render={({ field }) => (
            <FormInput
              {...register("idNumber")}
              type="number"
              placeholder="Enter Last 6-Digit of ID"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              error={errors?.idNumber && errors.idNumber.message}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          name="referredBy"
          control={control}
          render={({ field }) => (
            <FormInput
              {...register("referredBy")}
              type="number"
              placeholder="Enter Your Phone No"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              error={errors?.referredBy && errors.referredBy.message}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <p className="text-md italic font-semibold">Total Rs: 1000 PKR</p>
      </div>
      <Button
        type="submit"
        value={isPaymentLoading ? "Processing..." : "Pay Now"}
        variant="primary"
        height={50}
        disabled={isPaymentLoading}
      />
    </form>
  );
};
