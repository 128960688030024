import React from "react";
import { Button } from "components/common/base/button";
import { useSelector } from "react-redux";

const LocCard = ({
  data,
  handleDeleteAddress,
  countFlag,
  onUpdate,
  onPayNow,
}) => {
  const authReducer = useSelector((state) => state.auth);
  const { deleteAddressLoader } = authReducer;

  return (
    <div className="w-full flex p-5 flex-col shadow-card  rounded-2xl">
      <p className="font-Roboto text-[16px] truncate">
        <span className="font-Roboto text-primary text-[18px]">Shop Name:</span>{" "}
        {data.shop ? data.shop : "Not Provided"}
      </p>
      <p className="font-Roboto text-[16px] truncate">
        <span className="font-Roboto text-primary text-[18px]">Address:</span>{" "}
        {data.address && data.address}
      </p>
      <p className="font-Roboto text-[16px] truncate">
        <span className="font-Roboto text-primary text-[18px]">Province:</span>{" "}
        {data.province &&
          data.province
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
      </p>

      <p className="font-Roboto text-[16px] truncate">
        <span className="font-Roboto text-primary text-[18px]">Tehsil:</span>{" "}
        {data.tehsil &&
          data.tehsil.charAt(0).toUpperCase() + data.tehsil.slice(1)}
      </p>
      <p className="font-Roboto text-[16px] truncate">
        <span className="font-Roboto text-primary text-[18px]">District:</span>{" "}
        {data.district &&
          data.district.charAt(0).toUpperCase() + data.district.slice(1)}
      </p>
      <p className="font-Roboto text-[16px] truncate">
        <span className="font-Roboto text-primary text-[18px]">Status:</span>{" "}
        {data.activated && data.activated ? "Activated" : "Not Activated"}
      </p>
      <div className="flex items-center gap-4 mt-5">
        <Button
          variant="primary"
          width={120}
          value="Update"
          height={40}
          onClick={() => onUpdate(data)}
        />
        {/* <Button
        variant="primary"
        width={120}
        value="Delete"
        height={40}
        // loader={deleteAddressLoader}
        disabled={deleteAddressLoader || countFlag}
        onClick={()=>handleDeleteAddress(data.id)}
      /> */}
        {!data.activated && (
          <Button
            variant="primary"
            width={120}
            value="Pay Now"
            height={40}
            onClick={onPayNow}
          />
        )}
      </div>
    </div>
  );
};

export default LocCard;
