import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Button } from "../components/common/base/button";
import Layout from "layout/BaseLayout";

const Simulator = () => {
  const location = useLocation();
  const [solutions, setSolutions] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [showAddresses, setShowAddresses] = useState(false);

  useEffect(() => {
    const params = queryString.parse(location.search);
    performActionBasedOnFormula(params);
  }, [location.search]);

  useEffect(() => {
    if (selectedSolution !== null) {
      fetchInitialData();
    }
  }, [selectedSolution]);

  useEffect(() => {
    if (selectedProduct) {
      const matchedAddresses =
        addresses.find((address) => address.id === selectedProduct.id)
          ?.addresses || [];
      setFilteredAddresses(matchedAddresses);
    }
  }, [selectedProduct, addresses]);

  const performActionBasedOnFormula = (params) => {
    if (!params.solutions) return;
    const [solutionsStr, tehsil, district, province] =
      params.solutions.split("?");
    const updatedParams = {
      solutions: solutionsStr,
      tehsil: tehsil.split("=")[1],
      district: district.split("=")[1],
      province: province.split("=")[1],
    };
    fetchDataForFormula(updatedParams);
  };

  const fetchInitialData = async () => {
    try {
      if (
        selectedSolution === null ||
        selectedSolution < 0 ||
        !solutions[selectedSolution]
      ) {
        console.error("No valid solution selected");
        return;
      }

      const selectedSolutionData = solutions[selectedSolution];
      console.log("Selected Solution Data:", selectedSolutionData);
      const queryParams = selectedSolutionData.map(
        ({ id, uuid, availableLocation, tehsil }) => ({
          id,
          uuid,
          availableLocation,
          tehsil,
        })
      );

      const queryString = JSON.stringify(queryParams);
      const url = `https://farmacie.agronomics.pk/api/api/simulator/single/solution?solutions=${queryString}`;
      console.log(url, "urlsss");
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Addresses Data:", data);
      setAddresses(data.data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const fetchDataForFormula = async (params) => {
    try {
      const { tehsil, district, province, solutions } = params;
      // https://farmacie.agronomics.pk/api/api
      // http://192.168.200.63:2033/
      const url = `https://farmacie.agronomics.pk/api/api/simulator/all/solution?tehsil=${tehsil.trim()}&district=${district.trim()}&province=${province.trim()}&solutions=${solutions}`;
      console.log(url, "url");
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const allSolutions = Object.keys(data.data).map((key) => data.data[key]);
      console.log("Solutions Data:", allSolutions);
      setSolutions(allSolutions);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const getLocation = (solution) => {
    const locationKeys = ["tehsil", "district", "province"];
    for (let key of locationKeys) {
      if (solution[key]) {
        return { key, value: solution[key] };
      }
    }
    return { key: null, value: null };
  };

  const handleFindProducts = (index) => {
    setSelectedSolution(index);
    setShowAddresses(false);
  };

  const handleViewLocationsAndPricing = (product) => {
    setSelectedProduct(product);
    setShowAddresses(true);
  };

  const handleBackToSolutions = () => {
    setSelectedSolution(null);
    setSelectedProduct(null);
  };

  const handleBackToProducts = () => {
    setShowAddresses(false);
    setSelectedProduct(null);
  };

  const renderAddresses = (addresses) => {
    if (!addresses.length) {
      return (
        <h3 className="text-2xl font-bold mb-2">No Addresses Available</h3>
      );
    }

    return (
      <>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {addresses.map((address, index) => (
            <div key={index} className="rounded shadow">
              <div className="bg-gray-200 p-4 space-y-2 rounded-lg">
                <h1 className="w-full max-w-full text-center text-xl md:text-2xl font-bold capitalize text-primary">
                  {address.shop || "Shop Name"}
                </h1>
                <div className="text-sm md:text-[15.5px] capitalize italic flex flex-col items-start gap-4">
                  <div className="flex items-center gap-3">
                    <h3 className="font-bold not-italic text-primary">
                      Shop Address:
                    </h3>
                    <span>{address.address || "No address available"}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <h3 className="font-bold not-italic text-primary">
                      Tehsil:
                    </h3>
                    <span>{address.tehsil || "No tehsil available"}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <h3 className="font-bold not-italic text-primary">
                      District:
                    </h3>
                    <span>{address.district || "No district available"}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <h3 className="font-bold not-italic text-primary">
                      Province:
                    </h3>
                    <span>{address.province || "No province available"}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <h3 className="font-bold not-italic text-primary">
                      Seller Name:
                    </h3>
                    <span>
                      {address.sellerName || "No seller name available"}
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <h3 className="font-bold not-italic text-primary">
                      Price:
                    </h3>
                    <span>
                      {address.price
                        ? `Rs: ${address.price}`
                        : "No price available"}
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <h3 className="font-bold not-italic text-primary">
                      Contact:
                    </h3>
                    <span>{address.sellerPhone || "No contact available"}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <Layout>
      <section className="text-gray-600 body-font">
        <div className="p-10 md:container md:px-5 py-14 mx-auto">
          {selectedSolution === null ? (
            <div className="md:flex flex-wrap">
              {solutions.length > 0 ? (
                solutions.map((solutionGroup, i) => (
                  <div key={i} className="py-4 md:px-4 md:w-1/3">
                    <div className="flex rounded-lg h-full bg-gray-100 p-4 md:p-8 flex-col">
                      <div className="flex items-center mb-3">
                        <h2 className="text-[#668968] text-xl font-medium">
                          Solutions {i + 1}
                        </h2>
                      </div>
                      <div className="flex-grow">
                        <h2 className="text-[#668968] text-xl font-medium">
                          Product(s):
                        </h2>
                        <div>
                          {solutionGroup.map((product, j) => (
                            <span
                              key={j}
                              className="inline-flex mx-1 my-1 items-center bg-[#668968] text-white rounded-full px-3 py-1 text-sm font-semibold"
                            >
                              {product.name}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="flex-grow">
                        <h2 className="text-[#668968] text-xl font-medium mt-4">
                          Availability:
                        </h2>
                        {solutionGroup.map((product, j) => {
                          const location = getLocation(product);
                          return (
                            <div
                              key={j}
                              className="bg-[#668968] text-white rounded-md mt-2 px-3 py-1 text-sm font-semibold flex flex-col gap-1 w-fit"
                            >
                              <h2>Name: {product.name}</h2>
                              {product.availableLocation !== "none" ? (
                                <h2>
                                  Availability:{" "}
                                  {`${product.availableLocation} (${location.value})`}
                                </h2>
                              ) : (
                                <h2>No Available on farmacie</h2>
                              )}
                            </div>
                          );
                        })}
                        <div className="mt-6">
                          <Button
                            value="Find Product(s)"
                            variant="primary"
                            width={140}
                            height={40}
                            font="Roboto"
                            onClick={() => handleFindProducts(i)} // Set the selected solution
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No solutions available.</p>
              )}
            </div>
          ) : !showAddresses ? (
            <div className="mt-6">
              <Button
                value="Back to Solutions"
                variant="primary"
                width={160}
                height={40}
                font="Roboto"
                onClick={handleBackToSolutions}
              />
              <h2 className="text-2xl font-bold mt-4">
                Products for Solution {selectedSolution + 1}
              </h2>
              <div className="flex flex-wrap">
                {solutions[selectedSolution].map((product, j) => (
                  <div key={j} className="py-4 md:px-4 md:w-1/2">
                    <div className="rounded shadow">
                      <div className="bg-primary text-white rounded-t-lg">
                        <h1 className="w-full max-w-full p-4 h-16 flex items-center text-2xl font-bold capitalize">
                          {product.name}
                        </h1>
                      </div>
                      <div className="bg-gray-200 p-4 space-y-2 rounded-b-lg">
                        <h1 className="w-full max-w-full text-center text-xl md:text-2xl font-bold capitalize underline">
                          Product Information
                        </h1>
                        <div className="h-full md:px-8 pt-2 pb-4 rounded-xl overflow-hidden text-left relative transform hover:scale-105 transition">
                          <div className="grid grid-cols-2 mb-3 gap-y-1.5 text-sm md:text-[15.5px] capitalize italic">
                            <h3 className="font-semibold not-italic">
                              Product Type:
                            </h3>
                            <span>{product.ProductType}</span>

                            <h3 className="font-semibold not-italic">
                              Sub Product Type:
                            </h3>
                            <span>{product.subProductType}</span>

                            <h3 className="font-semibold not-italic">
                              Product Brand:
                            </h3>
                            <span>{product.brand}</span>

                            <h3 className="font-semibold not-italic">
                              Category:
                            </h3>
                            <span>{product.category}</span>

                            <h3 className="font-semibold not-italic">
                              Package Weight:
                            </h3>
                            <span>
                              {product.pkgWeight} {product.weightUnit}
                            </span>

                            <h3 className="font-semibold not-italic">
                              Package Type:
                            </h3>
                            <span>{product.pkgType}</span>

                            <h3 className="font-semibold not-italic">
                              Description:
                            </h3>
                            <span>{product.description}</span>
                          </div>
                          <div className="mt-6">
                            <Button
                              value="View Locations & Pricing"
                              variant="primary"
                              width={220}
                              height={40}
                              font="Roboto"
                              onClick={() =>
                                handleViewLocationsAndPricing(product)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              <h2 className="text-2xl font-bold mb-2">
                Addresses for Product: {selectedProduct?.name || "N/A"}
              </h2>
              <Button
                value="Back to Products"
                variant="primary"
                width={160}
                height={40}
                font="Roboto"
                onClick={handleBackToProducts}
              />
            </>
          )}
          {selectedProduct && renderAddresses(filteredAddresses)}
        </div>
      </section>
    </Layout>
  );
};

export default Simulator;
