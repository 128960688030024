import React, { useState } from "react";
import FormInput from "components/common/base/FormInput";
import { Button } from "components/common/base/button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { updateAddLocationSchema } from "helpers/schema";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { pakistanData } from "helpers/constant";
import SelectInput from "components/common/base/SelectInput";
import { isEmpty } from "lodash";

const AddUpdateAdddress = ({ onSubmit, defaultValues }) => {
  const authReducer = useSelector((state) => state.auth);
  const { addAddressLoader } = authReducer;
  const [shopFlag, setShopFlag] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateAddLocationSchema(shopFlag)),
    defaultValues,
  });

  const handleProvinceChange = (selectedValue) => {
    setSelectedProvince(selectedValue);
    setSelectedDistrict(null);
  };

  const handleDistrictChange = (selectedValue) => {
    setSelectedDistrict(selectedValue);
  };

  let districtOptions = [];
  if (selectedProvince) {
    districtOptions = pakistanData[`districts_${selectedProvince}`] || [];
  }

  // Determine which tehsil options to show based on selected district
  let tehsilOptions = [];
  if (selectedDistrict) {
    tehsilOptions = pakistanData[`tehsils_${selectedDistrict}`] || [];
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 gap-5">
        <div className="col-span-1">
          <Controller
            name="province"
            control={control}
            render={({ field }) => (
              <SelectInput
                {...register("province")}
                placeholder="Select Province"
                value={field.value}
                options={pakistanData.provinces}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  handleProvinceChange(e.target.value);
                }}
                error={errors?.province && errors.province.message}
              />
            )}
          />
        </div>
        <div className="col-span-1">
          <Controller
            name="district"
            control={control}
            render={({ field }) => (
              <SelectInput
                {...register("district")}
                placeholder="Select District"
                value={field.value}
                options={districtOptions}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  handleDistrictChange(e.target.value);
                }}
                error={errors?.district && errors.district.message}
              />
            )}
          />
        </div>
        <div className="col-span-1">
          <Controller
            name="tehsil"
            control={control}
            render={({ field }) => (
              <SelectInput
                {...register("tehsil")}
                placeholder="Select Tehsil"
                value={field.value}
                options={tehsilOptions}
                onChange={(e) => field.onChange(e.target.value)}
                error={errors?.tehsil && errors.tehsil.message}
              />
            )}
          />
        </div>

        {isEmpty(defaultValues) && (
          <div className="col-span-1">
            <FormControlLabel
              control={
                <Checkbox
                  checked={shopFlag}
                  onChange={() => setShopFlag(!shopFlag)}
                />
              }
              label="Are you a shopkeeper?"
            />
          </div>
        )}
        {(shopFlag || (!isEmpty(defaultValues) && defaultValues.shop)) && (
          <div className="col-span-1">
            <Controller
              name="shop"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...register("shop")}
                  placeholder="Enter shop name"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  error={errors?.shop && errors.shop.message}
                />
              )}
            />
          </div>
        )}
        <div className="col-span-1">
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <FormInput
                {...register("address")}
                placeholder="Enter Address"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                error={errors?.address && errors.address.message}
              />
            )}
          />
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <Button
            value="Submit"
            type="submit"
            variant="primary"
            width={140}
            height={45}
            disabled={addAddressLoader}
            loader={addAddressLoader}
          />
        </div>
      </div>
    </form>
  );
};

export default AddUpdateAdddress;
