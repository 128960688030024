export const roles = [
  { name: "Seller", id: "1", type: "seller" },
  { name: "Buyer", id: "2", type: "buyer" },
];

export const category = [
  // { id: 1, label: "Fruits", value: "Fruits" },
  // { id: 2, label: "Vegetables", value: "Vegetables" },
  { id: 1, label: "Fertilizers", value: "fertilizers" },
  { id: 2, label: "Pesticides", value: "pesticides" },
  { id: 3, label: "Crop Nutritions", value: "crop nutritions" },
  // {
  //   id: 4,
  //   label: "Fiber & Oil Seed Crops",
  //   value: "Fiber & Oil Seed Crops",
  // },
  // { id: 5, label: "Grains & Cereals", value: "Grains & Cereals" },
  // {
  //   id: 6,
  //   label: "Plant Pathology & Entomology",
  //   value: "Plant Pathology & Entomology",
  // },
  // { id: 7, label: "Seed Varieties", value: "Seed Varieties" },
  // { id: 8, label: "Machinary & Tools", value: "Machinary & Tools" },
];

export const brands = [
  { id: 1, label: "agri innotech", value: "agri innotech" },
  { id: 2, label: "agritech", value: "agritech" },
  { id: 3, label: "agpharma", value: "agpharma" },
  { id: 4, label: "agro guard", value: "agro guard" },
  { id: 5, label: "agromart group", value: "agromart group" },
  { id: 6, label: "alnoor agro chemicals", value: "alnoor agro chemicals" },
  { id: 7, label: "arysta", value: "arysta" },
  { id: 8, label: "barkat fertilizers", value: "barkat fertilizers" },
  { id: 9, label: "bayer crop sciences", value: "bayer crop sciences" },
  {
    id: 10,
    label: "bio ag service pakistan",
    value: "bio ag service pakistan",
  },
  { id: 11, label: "byter crop protection", value: "byter crop protection" },
  { id: 12, label: "dap", value: "dap" },
  { id: 13, label: "djc", value: "djc" },
  { id: 14, label: "engro fertilizer ltd", value: "engro fertilizer ltd" },
  { id: 15, label: "evyol group", value: "evyol group" },
  { id: 16, label: "farm evo", value: "farm evo" },
  {
    id: 17,
    label: "fatima fertilizer company ltd",
    value: "fatima fertilizer company ltd",
  },
  { id: 18, label: "fertiscience", value: "fertiscience" },
  {
    id: 19,
    label: "ffc - fauji fertilizer company",
    value: "ffc - fauji fertilizer company",
  },
  { id: 20, label: "fmc corporation", value: "fmc corporation" },
  { id: 21, label: "four brothers", value: "four brothers" },
  { id: 22, label: "global products", value: "global products" },
  { id: 23, label: "gr agro service", value: "gr agro service" },
  { id: 24, label: "green circle", value: "green circle" },
  {
    id: 25,
    label: "green pakistan nature farming",
    value: "green pakistan nature farming",
  },
  { id: 26, label: "green crop", value: "green crop" },
  { id: 27, label: "greenlet international", value: "greenlet international" },
  { id: 28, label: "grow plant pvt. ltd", value: "grow plant pvt. ltd" },
  { id: 29, label: "hara organic pakistan", value: "hara organic pakistan" },
  { id: 30, label: "higgro", value: "higgro" },
  { id: 31, label: "hmc", value: "hmc" },
  { id: 32, label: "ici pakistan", value: "ici pakistan" },
  { id: 33, label: "ics tara group", value: "ics tara group" },
  {
    id: 34,
    label: "jaffer brothers agro services pvt. ltd",
    value: "jaffer brothers agro services pvt. ltd",
  },
  { id: 35, label: "jalunder", value: "jalunder" },
  { id: 36, label: "kanzo", value: "kanzo" },
  { id: 37, label: "kissan agro", value: "kissan agro" },
  { id: 38, label: "manuchar agro", value: "manuchar agro" },
  { id: 39, label: "matra asia pvt. ltd", value: "matra asia pvt. ltd" },
  { id: 40, label: "maxim agri pvt. ltd", value: "maxim agri pvt. ltd" },
  { id: 41, label: "mynita plus", value: "mynita plus" },
  { id: 42, label: "nuchem", value: "nuchem" },
  { id: 43, label: "orgevit", value: "orgevit" },
  { id: 43, label: "samsayal", value: "samsayal" },
  { id: 44, label: "perfect chemical", value: "perfect chemical" },
  { id: 45, label: "plant for life", value: "plant for life" },
  { id: 46, label: "range", value: "range" },
  { id: 47, label: "rudolf group", value: "rudolf group" },
  { id: 48, label: "sarsabz", value: "sarsabz" },
  { id: 49, label: "saver international", value: "saver international" },
  {
    id: 50,
    label: "shifang anda chemical co",
    value: "shifang anda chemical co",
  },
  { id: 51, label: "sinopak", value: "sinopak" },
  {
    id: 52,
    label: "solex chemical pvt. ltd",
    value: "solex chemical pvt. ltd",
  },
  { id: 53, label: "sun crop", value: "sun crop" },
  {
    id: 54,
    label: "suraj fertilizer industries pvt. ltd",
    value: "suraj fertilizer industries pvt. ltd",
  },
  { id: 55, label: "swat agro chemicals", value: "swat agro chemicals" },
  { id: 56, label: "syngenta", value: "syngenta" },
  { id: 57, label: "tara group", value: "tara group" },
  { id: 58, label: "target", value: "target" },
  { id: 59, label: "top sun", value: "top sun" },
  {
    id: 60,
    label: "united agro fertilizers (7 star fertilizer)",
    value: "united agro fertilizers (7 star fertilizer)",
  },
  { id: 61, label: "vcs", value: "vcs" },
  { id: 62, label: "ventage", value: "ventage" },
  { id: 63, label: "vision crop sciences", value: "vision crop sciences" },
  { id: 64, label: "welcon", value: "welcon" },
  { id: 65, label: "wuxal (brand of BASF)", value: "wuxal (brand of BASF)" },
  {
    id: 66,
    label: "zhengband agriculture pakistan pvt. ltd",
    value: "zhengband agriculture pakistan pvt. ltd",
  },
  { id: 67, label: "zinkron", value: "zinkron" },
].sort((a, b) => a.label.localeCompare(b.label));

export const packagingType = [
  { id: 1, label: "Blastic Bottle", value: "plasticbottle" },
  { id: 2, label: "Plastic Bags", value: "plasticbag" },
  { id: 3, label: "Aluminium Bottle", value: "aluminiumbottle" },
  { id: 4, label: "Cloth Bags", value: "clothbag" },
  { id: 5, label: "Burlap Bags", value: "burlapbags" },
  { id: 6, label: "Boxes / Crates", value: "boxescrates" },
];

export const weightUnitType = [
  { id: 1, label: "Gram", value: "gram" },
  { id: 2, label: "Milligram ", value: "milligram " },
  { id: 3, label: "Kg", value: "kg" },
  { id: 4, label: "Liter", value: "liter" },
  { id: 5, label: "Milliliter ", value: "milliliter " },
  { id: 6, label: "Dozen", value: "dozen" },
  { id: 7, label: "Sankara", value: "sankara" },
  { id: 8, label: "Percentage  ", value: "percentage" },
  { id: 8, label: "Mun", value: "mun" },
];

export const yesNoOption = [
  { id: 1, label: "Yes", value: "yes" },
  { id: 2, label: "No", value: "no" },
];

export const taxOpt = [
  { id: 1, label: "Inclusive", value: "inclusive" },
  { id: 2, label: "Exclusive", value: "exclusive" },
];

export const shippingOption = [
  { id: 1, label: "Free", value: "free" },
  { id: 2, label: "Amount", value: "amount" },
];

export const seedTypeOption = [
  { id: 1, label: "Hybrid", value: "hybrid" },
  { id: 2, label: "Synthetic", value: "synthetic" },
  { id: 2, label: "Organic", value: "organic" },
];

export const seedRegionOption = [
  { id: 1, label: "Irrigated", value: "irrigated" },
  { id: 2, label: "Rainfed", value: "rainfed" },
  { id: 2, label: "Drought", value: "drought" },
];

export const machinaryToolsOption = [
  { id: 1, label: "Tool", value: "Tool" },
  { id: 2, label: "Machinary", value: "Machinary" },
];

export const toolCondition = [
  { id: 1, label: "New", value: "new" },
  { id: 2, label: "Used", value: "used" },
];

export const pakistanData = {
  provinces: [
    { label: "Azad Kashmir", value: "azad_kashmir" },
    { label: "Balochistan", value: "balochistan" },
    { label: "Gilgit Baltistan", value: "gilgit_baltistan" },
    { label: "Islamabad", value: "islamabad" },
    { label: "KPK", value: "kpk" },
    { label: "Punjab", value: "punjab" },
    { label: "Sindh", value: "sindh" },
  ],
  districts_islamabad: [{ label: "Islamabad", value: "islamabad" }],
  tehsils_islamabad: [{ label: "Islamabad", value: "islamabad" }],
  districts_azad_kashmir: [
    { label: "Bagh", value: "bagh" },
    { label: "Bhimber", value: "bhimber" },
    { label: "Hattian Bala", value: "hattian_bala" },
    { label: "Haveli", value: "haveli" },
    { label: "Mirpur", value: "mirpur" },
    { label: "Muzaffarabad", value: "muzaffarabad" },
    { label: "Neelum", value: "neelum" },
    { label: "Poonch", value: "poonch" },
    { label: "Sudhnoti", value: "sudhnoti" },
  ],

  tehsils_bagh: [
    { label: "Athmuqam", value: "athmuqam" },
    { label: "Sharda", value: "sharda" },
  ],
  tehsils_bhimber: [
    { label: "Balouch", value: "balouch" },
    { label: "Dadyal", value: "dadyal" },
    { label: "Islamgarh", value: "islamgarh" },
    { label: "Mang", value: "mang" },
    { label: "Mirpur", value: "mirpur" },
    { label: "Pallandri", value: "pallandri" },
    { label: "Tarar Khel", value: "tarar_khel" },
  ],
  tehsils_hattian_bala: [
    { label: "Barnala", value: "barnala" },
    { label: "Bhimber", value: "bhimber" },
    { label: "Samahni", value: "samahni" },
  ],
  tehsils_haveli: [
    { label: "Bagh", value: "bagh" },
    { label: "Birpani", value: "birpani" },
    { label: "Dhirkot", value: "dhirkot" },
    { label: "Hari Ghel", value: "hari_ghel" },
    { label: "Rera", value: "rera" },
  ],
  tehsils_mirpur: [
    { label: "Charhoi", value: "charhoi" },
    { label: "Duliah Jattan", value: "duliah_jattan" },
    { label: "Fatehpur Thakiala", value: "fatehpur_thakiala" },
    { label: "Khuiratta", value: "khuiratta" },
    { label: "Kotli", value: "kotli" },
    { label: "Sehnsa", value: "sehnsa" },
  ],
  tehsils_muzaffarabad: [
    { label: "Chikkar", value: "chikkar" },
    { label: "Hattian Bala", value: "hattian_bala" },
    { label: "Leepa", value: "leepa" },
  ],
  tehsils_neelum: [
    { label: "Muzaffarabad", value: "muzaffarabad" },
    { label: "Nasirbad", value: "nasirbad" },
  ],
  tehsils_poonch: [
    { label: "Haveli", value: "haveli" },
    { label: "Khurshidabad", value: "khurshidabad" },
    { label: "Mumtazabad", value: "mumtazabad" },
  ],
  tehsils_sudhnoti: [
    { label: "Abbaspur", value: "abbaspur" },
    { label: "Hajira", value: "hajira" },
    { label: "Rawalakot", value: "rawalakot" },
    { label: "Thorar", value: "thorar" },
  ],
  districts_balochistan: [
    { label: "Awaran", value: "awaran" },
    { label: "Barkhan", value: "barkhan" },
    { label: "Chagai", value: "chagai" },
    { label: "Dera Bugti", value: "dera_bugti" },
    { label: "Duki", value: "duki" },
    { label: "Gwadar", value: "gwadar" },
    { label: "Harnai", value: "harnai" },
    { label: "Jafarabad", value: "jafarabad" },
    { label: "Jhal Magsi", value: "jhal_magsi" },
    { label: "Kachi", value: "kachi" },
    { label: "Kalat", value: "kalat" },
    { label: "Kech", value: "kech" },
    { label: "Kharan", value: "kharan" },
    { label: "Khuzdar", value: "khuzdar" },
    { label: "Killa Abdullah", value: "killa_abdullah" },
    { label: "Killa Saifullah", value: "killa_saifullah" },
    { label: "Kohlu", value: "kohlu" },
    { label: "Lasbela", value: "lasbela" },
    { label: "Lehri", value: "lehri" },
    { label: "Loralai", value: "loralai" },
    { label: "Mastung", value: "mastung" },
    { label: "Musakhel", value: "musakhel" },
    { label: "Nasirabad", value: "nasirabad" },
    { label: "Nushki", value: "nushki" },
    { label: "Panjgur", value: "panjgur" },
    { label: "Pishin", value: "pishin" },
    { label: "Quetta", value: "quetta" },
    { label: "Sherani", value: "sherani" },
    { label: "Sibi", value: "sibi" },
    { label: "Sohbatpur", value: "sohbatpur" },
    { label: "Washuk", value: "washuk" },
    { label: "Zhob", value: "zhob" },
    { label: "Ziarat", value: "ziarat" },
  ],
  tehsils_awaran: [
    { label: "Awaran", value: "awaran" },
    { label: "Gishkaur", value: "gishkaur" },
    { label: "Jhal Jhao", value: "jhal_jhao" },
    { label: "Korak Jahoo", value: "korak_jahoo" },
    { label: "Mashkay", value: "mashkay" },
  ],
  tehsils_barkhan: [{ label: "Barkhan", value: "barkhan" }],
  tehsils_chagai: [
    { label: "Chagai", value: "chagai" },
    { label: "Dalbandin", value: "dalbandin" },
    { label: "Nokundi", value: "nokundi" },
    { label: "Taftan", value: "taftan" },
  ],
  tehsils_dera_bugti: [
    { label: "Baiker", value: "baiker" },
    { label: "Dera Bugti", value: "dera_bugti" },
    { label: "Phelawagh", value: "phelawagh" },
    { label: "Sui", value: "sui" },
  ],
  tehsils_duki: [{ label: "Duki", value: "duki" }],
  tehsils_gwadar: [
    { label: "Gwadar", value: "gwadar" },
    { label: "Jiwani", value: "jiwani" },
    { label: "Ormara", value: "ormara" },
    { label: "Pasni", value: "pasni" },
    { label: "Suntsar", value: "suntsar" },
  ],
  tehsils_harnai: [
    { label: "Harnai", value: "harnai" },
    { label: "Khoast", value: "khoast" },
    { label: "Shahrig", value: "shahrig" },
  ],
  tehsils_jafarabad: [
    { label: "Gandakha", value: "gandakha" },
    { label: "Jhatpat", value: "jhatpat" },
    { label: "Usta Mohammad", value: "usta_mohammad" },
  ],
  tehsils_jhal_magsi: [
    { label: "Gandawa", value: "gandawa" },
    { label: "Jhal Magsi", value: "jhal_magsi" },
    { label: "Mirpur", value: "mirpur" },
  ],
  tehsils_kachi: [
    { label: "Balanari", value: "balanari" },
    { label: "Dhadar", value: "dhadar" },
    { label: "Khattan", value: "khattan" },
    { label: "Mach", value: "mach" },
    { label: "Sani", value: "sani" },
  ],
  tehsils_kalat: [
    { label: "Gazg", value: "gazg" },
    { label: "Johan", value: "johan" },
    { label: "Kalat", value: "kalat" },
    { label: "Mangochar", value: "mangochar" },
    { label: "Surab", value: "surab" },
  ],
  tehsils_kech: [
    { label: "Balnigor", value: "balnigor" },
    { label: "Buleda", value: "buleda" },
    { label: "Dasht", value: "dasht" },
    { label: "Gayab", value: "gayab" },
    { label: "Mand", value: "mand" },
    { label: "Solband", value: "solband" },
    { label: "Tump", value: "tump" },
    { label: "Turbat", value: "turbat" },
    { label: "Zamuran", value: "zamuran" },
  ],
  tehsils_kharan: [
    { label: "Kharan", value: "kharan" },
    { label: "Sar-Kharan", value: "sar-kharan" },
    { label: "Tohumulk", value: "tohumulk" },
  ],
  tehsils_khuzdar: [
    { label: "Aranji", value: "aranji" },
    { label: "Baghbana", value: "baghbana" },
    { label: "Greshek", value: "greshek" },
    { label: "Karkh", value: "karkh" },
    { label: "Khuzdar", value: "khuzdar" },
    { label: "Moola", value: "moola" },
    { label: "Naal", value: "naal" },
    { label: "Ornach", value: "ornach" },
    { label: "Saroona", value: "saroona" },
    { label: "Wadh", value: "wadh" },
    { label: "Zehri", value: "zehri" },
  ],
  tehsils_killa_abdullah: [
    { label: "Chaman", value: "chaman" },
    { label: "Dobandi", value: "dobandi" },
    { label: "Gulistan", value: "gulistan" },
    { label: "Killa Abdullah", value: "killa_abdullah" },
  ],
  tehsils_killa_saifullah: [
    { label: "Badini", value: "badini" },
    { label: "Kanmetharzai", value: "kanmetharzai" },
    { label: "Killa Saifullah", value: "killa_saifullah" },
    { label: "Loiband", value: "loiband" },
    { label: "Muslim Bagh", value: "muslim_bagh" },
    { label: "Shinki", value: "shinki" },
  ],
  tehsils_kohlu: [
    { label: "Grisini", value: "grisini" },
    { label: "Kahan", value: "kahan" },
    { label: "Kohlu", value: "kohlu" },
    { label: "Maiwand", value: "maiwand" },
    { label: "Tamboo", value: "tamboo" },
  ],
  tehsils_lasbela: [
    { label: "Bela", value: "bela" },
    { label: "Dureji", value: "dureji" },
    { label: "Hub", value: "hub" },
    { label: "Kanraj", value: "kanraj" },
    { label: "Uthal", value: "uthal" },
  ],
  tehsils_lehri: [
    { label: "Bhag", value: "bhag" },
    { label: "Lehri", value: "lehri" },
  ],
  tehsils_loralai: [
    { label: "Bori", value: "bori" },
    { label: "Mekhtar", value: "mekhtar" },
  ],
  tehsils_mastung: [
    { label: "Dasht Mastung", value: "dasht_mastung" },
    { label: "Kardigap", value: "kardigap" },
    { label: "Khad Koocha", value: "khad_koocha" },
    { label: "Mastung", value: "mastung" },
  ],
  tehsils_musakhel: [
    { label: "Darug", value: "darug" },
    { label: "Kingri", value: "kingri" },
    { label: "Musakhel", value: "musakhel" },
    { label: "Toisar", value: "toisar" },
  ],
  tehsils_nasirabad: [
    { label: "Baba Kot", value: "baba_kot" },
    { label: "Chattar", value: "chattar" },
    { label: "Dera Murad Jamali", value: "dera_murad_jamali" },
    { label: "Meer Hassan", value: "meer_hassan" },
    { label: "Tamboo", value: "tamboo" },
  ],
  tehsils_nushki: [
    { label: "Dak", value: "dak" },
    { label: "Nushki", value: "nushki" },
  ],
  tehsils_panjgur: [
    { label: "Gichk", value: "gichk" },
    { label: "Gowargo", value: "gowargo" },
    { label: "Panjgur", value: "panjgur" },
    { label: "Paroom", value: "paroom" },
  ],
  tehsils_pishin: [
    { label: "Barshore", value: "barshore" },
    { label: "Hurramzai", value: "hurramzai" },
    { label: "Karezat", value: "karezat" },
    { label: "Khanozai", value: "khanozai" },
    { label: "Pishin", value: "pishin" },
    { label: "Saranan", value: "saranan" },
  ],
  tehsils_quetta: [
    { label: "Chiltan", value: "chiltan" },
    { label: "Panjpai", value: "panjpai" },
    { label: "Sadar", value: "sadar" },
    { label: "Zarghoon", value: "zarghoon" },
  ],
  tehsils_sherani: [{ label: "Sherani", value: "sherani" }],
  tehsils_sibi: [
    { label: "Kutmandai", value: "kutmandai" },
    { label: "Sangan", value: "sangan" },
    { label: "Sibi", value: "sibi" },
  ],
  tehsils_sohbatpur: [
    { label: "Faridabad", value: "faridabad" },
    { label: "Hayrvi", value: "hayrvi" },
    { label: "Manjipur", value: "manjipur" },
    { label: "Sohbatpur", value: "sohbatpur" },
  ],
  tehsils_washuk: [
    { label: "Besima", value: "besima" },
    { label: "Mashkel", value: "mashkel" },
    { label: "Nag", value: "nag" },
    { label: "Shahgori", value: "shahgori" },
    { label: "Washuk", value: "washuk" },
  ],
  tehsils_zhob: [
    { label: "Ashwat", value: "ashwat" },
    { label: "Kashatu", value: "kashatu" },
    { label: "Qamar Din Karez", value: "qamar_din_karez" },
    { label: "Sambaza", value: "sambaza" },
    { label: "Zhob", value: "zhob" },
  ],
  tehsils_ziarat: [
    { label: "Sinjavi", value: "sinjavi" },
    { label: "Ziarat", value: "ziarat" },
  ],
  districts_gilgit_baltistan: [
    { label: "Astore", value: "astore" },
    { label: "Darel", value: "darel" },
    { label: "Diamir", value: "diamir" },
    { label: "Ghanche", value: "ghanche" },
    { label: "Ghizer", value: "ghizer" },
    { label: "Gilgit", value: "gilgit" },
    { label: "Gupis-Yasin", value: "gupis_yasin" },
    { label: "Hunza", value: "hunza" },
    { label: "Kharmang", value: "kharmang" },
    { label: "Nagar", value: "nagar" },
    { label: "Rondu", value: "rondu" },
    { label: "Shigar", value: "shigar" },
    { label: "Skardu", value: "skardu" },
    { label: "Tangir", value: "tangir" },
  ],
  tehsils_astore: [
    { label: "Astore", value: "astore" },
    { label: "Shounter", value: "shounter" },
  ],
  tehsils_darel: [{ label: "Darel", value: "darel" }],
  tehsils_diamir: [
    { label: "Babusar", value: "babusar" },
    { label: "Chilas", value: "chilas" },
  ],
  tehsils_ghanche: [
    { label: "Chorbut", value: "chorbut" },
    { label: "Daghoni", value: "daghoni" },
    { label: "Haldi", value: "haldi" },
    { label: "Keris", value: "keris" },
    { label: "Khaplu", value: "khaplu" },
    { label: "Mashabrum", value: "mashabrum" },
  ],
  tehsils_ghizer: [
    { label: "Ishkoman", value: "ishkoman" },
    { label: "Punial", value: "punial" },
  ],
  tehsils_gilgit: [
    { label: "Danyor", value: "danyor" },
    { label: "Gilgit", value: "gilgit" },
    { label: "Juglot", value: "juglot" },
  ],
  "tehsils_gupis-yasin": [
    { label: "Gupis", value: "gupis" },
    { label: "Phander", value: "phander" },
    { label: "Yasin", value: "yasin" },
  ],
  tehsils_hunza: [
    { label: "Aliabad", value: "aliabad" },
    { label: "Gojal", value: "gojal" },
  ],
  tehsils_kharmang: [
    { label: "Gultari", value: "gultari" },
    { label: "Kharmang", value: "kharmang" },
    { label: "Tolti", value: "tolti" },
  ],
  tehsils_nagar: [
    { label: "Chalt", value: "chalt" },
    { label: "Nagar-i", value: "nagar-i" },
    { label: "Nagar-ii", value: "nagar-ii" },
  ],
  tehsils_rondu: [{ label: "Rondu", value: "rondu" }],
  tehsils_shigar: [
    { label: "Gulabpur", value: "gulabpur" },
    { label: "Shigar", value: "shigar" },
  ],
  tehsils_skardu: [
    { label: "Gamba", value: "gamba" },
    { label: "Gultari", value: "gultari" },
    { label: "Rondu", value: "rondu" },
    { label: "Skardu", value: "skardu" },
  ],
  tehsils_tangir: [{ label: "Tangir", value: "tangir" }],
  districts_kpk: [
    { label: "Abbottabad", value: "abbottabad" },
    { label: "Bajaur", value: "bajaur" },
    { label: "Bannu", value: "bannu" },
    { label: "Batagram", value: "batagram" },
    { label: "Buner", value: "buner" },
    { label: "Charsadda", value: "charsadda" },
    { label: "Dera Ismail Khan", value: "dera_ismail_khan" },
    { label: "Hangu", value: "hangu" },
    { label: "Haripur", value: "haripur" },
    { label: "Karak", value: "karak" },
    { label: "Khyber", value: "khyber" },
    { label: "Kohat", value: "kohat" },
    { label: "Kolai-Palas", value: "kolai_palas" },
    { label: "Kurram", value: "kurram" },
    { label: "Lakki Marwat", value: "lakki_marwat" },
    { label: "Lower Chitral", value: "lower_chitral" },
    { label: "Lower Dir", value: "lower_dir" },
    { label: "Lower Kohistan", value: "lower_kohistan" },
    { label: "Lower South Waziristan", value: "lower_south_waziristan" },
    { label: "Malakand", value: "malakand" },
    { label: "Mansehra", value: "mansehra" },
    { label: "Mardan", value: "mardan" },
    { label: "Mohmand", value: "mohmand" },
    { label: "North Waziristan", value: "north_waziristan" },
    { label: "Nowshera", value: "nowshera" },
    { label: "Orakzai", value: "orakzai" },
    { label: "Peshawar", value: "peshawar" },
    { label: "Shangla", value: "shangla" },
    { label: "Swabi", value: "swabi" },
    { label: "Swat", value: "swat" },
    { label: "Tank", value: "tank" },
    { label: "Torghar", value: "torghar" },
    { label: "Upper Chitral", value: "upper_chitral" },
    { label: "Upper Dir", value: "upper_dir" },
    { label: "Upper Kohistan", value: "upper_kohistan" },
    { label: "Upper South Waziristan", value: "upper_south_waziristan" },
  ],
  tehsils_abbottabad: [
    { label: "Abbottabad", value: "abbottabad" },
    { label: "Havelian", value: "havelian" },
    { label: "Lora", value: "lora" },
    { label: "Lower Tanawal", value: "lower tanawal" },
  ],
  tehsils_bajaur: [
    { label: "Barang", value: "barang" },
    { label: "Bar Chamarkand", value: "bar chamarkand" },
    { label: "Khar Bajaur", value: "khar bajaur" },
    { label: "Mamund", value: "mamund" },
    { label: "Nawagai", value: "nawagai" },
    { label: "Salarzai", value: "salarzai" },
    { label: "Utman Khel", value: "utman khel" },
  ],
  tehsils_bannu: [
    { label: "Baka Khel", value: "baka khel" },
    { label: "Bannu", value: "bannu" },
    { label: "Domel", value: "domel" },
    { label: "Kakki", value: "kakki" },
    { label: "Miryan", value: "miryan" },
    { label: "Wazir", value: "wazir" },
  ],
  tehsils_batagram: [
    { label: "Allai", value: "allai" },
    { label: "Batagram", value: "batagram" },
  ],
  tehsils_buner: [
    { label: "Chagharzai", value: "chagharzai" },
    { label: "Daggar", value: "daggar" },
    { label: "Gadezai", value: "gadezai" },
    { label: "Gagra", value: "gagra" },
    { label: "Khudu Khel", value: "khudu khel" },
    { label: "Mandanr", value: "mandanr" },
  ],
  tehsils_charsadda: [
    { label: "Charsadda", value: "charsadda" },
    { label: "Shabqadar", value: "shabqadar" },
    { label: "Tangi", value: "tangi" },
  ],
  tehsils_dera_ismail_khan: [
    { label: "Daraban", value: "daraban" },
    { label: "Darazinda", value: "darazinda" },
    { label: "Dera Ismail Khan", value: "dera ismail khan" },
    { label: "Kulachi", value: "kulachi" },
    { label: "Paharpur", value: "paharpur" },
    { label: "Paniala", value: "paniala" },
    { label: "Paroa", value: "paroa" },
  ],
  tehsils_hangu: [
    { label: "Doaba", value: "doaba" },
    { label: "Hangu", value: "hangu" },
    { label: "Tall", value: "tall" },
  ],
  tehsils_haripur: [
    { label: "Ghazi", value: "ghazi" },
    { label: "Haripur", value: "haripur" },
    { label: "Khanpur", value: "khanpur" },
  ],
  tehsils_karak: [
    { label: "Banda Daud Shah", value: "banda daud shah" },
    { label: "Karak", value: "karak" },
    { label: "Takht-e-Nasrati", value: "takht-e-nasrati" },
  ],
  tehsils_khyber: [
    { label: "Bagh Maidan", value: "bagh maidan" },
    { label: "Bara", value: "bara" },
    { label: "Bazar Zakha Khel", value: "bazar zakha khel" },
    { label: "Fort Salop", value: "fort salop" },
    { label: "Jamrud", value: "jamrud" },
    { label: "Landi Kotal", value: "landi kotal" },
    { label: "Mula Gori", value: "mula gori" },
    { label: "Painda Cheena", value: "painda cheena" },
  ],
  tehsils_kohat: [
    { label: "Dara Adam Khel", value: "dara adam khel" },
    { label: "Gumbat", value: "gumbat" },
    { label: "Kohat", value: "kohat" },
    { label: "Lachi", value: "lachi" },
  ],
  tehsils_kolai_palas: [
    { label: "Bataira / Kolai", value: "bataira / kolai" },
    { label: "Palas", value: "palas" },
  ],
  tehsils_kurram: [
    { label: "Central Kurram", value: "central kurram" },
    { label: "Lower Kurram", value: "lower kurram" },
    { label: "Upper Kurram", value: "upper kurram" },
  ],
  tehsils_lakki_marwat: [
    { label: "Bettani", value: "bettani" },
    { label: "Ghazni Khel", value: "ghazni khel" },
    { label: "Lakki Marwat", value: "lakki marwat" },
    { label: "Sari Naurang", value: "sari naurang" },
  ],
  tehsils_lower_chitral: [
    { label: "Chitral", value: "chitral" },
    { label: "Drosh", value: "drosh" },
  ],
  tehsils_lower_dir: [
    { label: "Adenzai", value: "adenzai" },
    { label: "Balambat", value: "balambat" },
    { label: "Khal", value: "khal" },
    { label: "Lal Qilla", value: "lal qilla" },
    { label: "Munda", value: "munda" },
    { label: "Samar Bagh", value: "samar bagh" },
    { label: "Timergara", value: "timergara" },
  ],
  tehsils_lower_kohistan: [
    { label: "Bankad", value: "bankad" },
    { label: "Pattan", value: "pattan" },
  ],
  tehsils_lower_south_waziristan: [
    { label: "Birmil", value: "birmil" },
    { label: "Shakai", value: "shakai" },
    { label: "Toi Khulla", value: "toi khulla" },
    { label: "Wana", value: "wana" },
  ],
  tehsils_malakand: [
    { label: "Sam Ranizai", value: "sam ranizai" },
    { label: "Swat Ranizai", value: "swat ranizai" },
    { label: "Thana Baizai", value: "thana baizai" },
    { label: "Utman Khel", value: "utman khel" },
  ],
  tehsils_mansehra: [
    { label: "Baffa Pakhal", value: "baffa pakhal" },
    { label: "Bala Kot", value: "bala kot" },
    { label: "Darband", value: "darband" },
    { label: "Mansehra", value: "mansehra" },
    { label: "Oghi", value: "oghi" },
    { label: "Tanawal", value: "tanawal" },
  ],
  tehsils_mardan: [
    { label: "Ghari Kapura", value: "ghari kapura" },
    { label: "Katlang", value: "katlang" },
    { label: "Mardan", value: "mardan" },
    { label: "Rustam", value: "rustam" },
    { label: "Takht Bhai", value: "takht bhai" },
  ],
  tehsils_mohmand: [
    { label: "Ambar Utman Khel", value: "ambar utman khel" },
    { label: "Halim Zai", value: "halim zai" },
    { label: "Pindiali", value: "pindiali" },
    { label: "Pran Ghar", value: "pran ghar" },
    { label: "Safi", value: "safi" },
    { label: "Upper Mohmand (Baizai)", value: "upper mohmand (baizai)" },
    { label: "Yake Ghund", value: "yake ghund" },
  ],
  tehsils_north_waziristan: [
    { label: "Datta Khel", value: "datta khel" },
    { label: "Dossali", value: "dossali" },
    { label: "Gharyum", value: "gharyum" },
    { label: "Ghulam Khan", value: "ghulam khan" },
    { label: "Mir Ali", value: "mir ali" },
    { label: "Miran Shah", value: "miran shah" },
    { label: "Razmak", value: "razmak" },
    { label: "Shewa", value: "shewa" },
    { label: "Spinwam", value: "spinwam" },
  ],
  tehsils_nowshera: [
    { label: "Jehangira", value: "jehangira" },
    { label: "Nowshera", value: "nowshera" },
    { label: "Pabbi", value: "pabbi" },
  ],
  tehsils_orakzai: [
    { label: "Central Orakzai", value: "central orakzai" },
    { label: "Ismail Zai", value: "ismail zai" },
    { label: "Lower Orakzai", value: "lower orakzai" },
    { label: "Upper Orakzai", value: "upper orakzai" },
  ],
  tehsils_peshawar: [
    { label: "Badbher", value: "badbher" },
    { label: "Chamkani", value: "chamkani" },
    { label: "Hassan Khel", value: "hassan khel" },
    { label: "Mathra", value: "mathra" },
    { label: "Peshawar City", value: "peshawar city" },
    { label: "Peshtakhara", value: "peshtakhara" },
    { label: "Shah Alam", value: "shah alam" },
  ],
  tehsils_shangla: [
    { label: "Alpuri", value: "alpuri" },
    { label: "Bisham", value: "bisham" },
    { label: "Chakesar", value: "chakesar" },
    { label: "Makhuzai", value: "makhuzai" },
    { label: "Martung", value: "martung" },
    { label: "Puran", value: "puran" },
    { label: "Shahpur", value: "shahpur" },
  ],
  tehsils_swabi: [
    { label: "Lahor", value: "lahor" },
    { label: "Razar", value: "razar" },
    { label: "Swabi", value: "swabi" },
    { label: "Topi", value: "topi" },
  ],
  tehsils_swat: [
    { label: "Babuzai", value: "babuzai" },
    { label: "Barikot", value: "barikot" },
    { label: "Behrain", value: "behrain" },
    { label: "Charbagh", value: "charbagh" },
    { label: "Kabal", value: "kabal" },
    { label: "Khwaza Khela", value: "khwaza khela" },
    { label: "Matta", value: "matta" },
  ],
  tehsils_tank: [
    { label: "Jandola", value: "jandola" },
    { label: "Tank", value: "tank" },
  ],
  tehsils_torghar: [
    { label: "Daur Maira", value: "daur maira" },
    { label: "Judba", value: "judba" },
    { label: "Khander Hassanzai", value: "khander hassanzai" },
  ],
  tehsils_upper_chitral: [
    { label: "Buni", value: "buni" },
    { label: "Mastuj", value: "mastuj" },
    { label: "Mulkhow", value: "mulkhow" },
    { label: "Torkhow", value: "torkhow" },
  ],
  tehsils_upper_dir: [
    { label: "Barawal", value: "barawal" },
    { label: "Dir", value: "dir" },
    { label: "Kalkot", value: "kalkot" },
    { label: "Lar Jam", value: "lar jam" },
    { label: "Sharingal", value: "sharingal" },
    { label: "Wari", value: "wari" },
  ],
  tehsils_upper_kohistan: [
    { label: "Dassu", value: "dassu" },
    { label: "Harban Basha", value: "harban basha" },
    { label: "Kandia", value: "kandia" },
    { label: "Seo", value: "seo" },
  ],
  tehsils_upper_south_waziristan: [
    { label: "Ladha", value: "ladha" },
    { label: "Makin", value: "makin" },
    { label: "Sararogha", value: "sararogha" },
    { label: "Sarwakai", value: "sarwakai" },
    { label: "Shaktoi", value: "shaktoi" },
    { label: "Shawal", value: "shawal" },
    { label: "Tiarza", value: "tiarza" },
  ],
  districts_punjab: [
    { label: "Attock", value: "attock" },
    { label: "Bahawalnagar", value: "bahawalnagar" },
    { label: "Bahawalpur", value: "bahawalpur" },
    { label: "Bhakkar", value: "bhakkar" },
    { label: "Chakwal", value: "chakwal" },
    { label: "Chiniot", value: "chiniot" },
    { label: "Dera Ghazi Khan", value: "dera_ghazi_khan" },
    { label: "Faisalabad", value: "faisalabad" },
    { label: "Gujranwala", value: "gujranwala" },
    { label: "Gujrat", value: "gujrat" },
    { label: "Hafizabad", value: "hafizabad" },
    { label: "Jampur", value: "jampur" },
    { label: "Jhang", value: "jhang" },
    { label: "Jhelum", value: "jhelum" },
    { label: "Kasur", value: "kasur" },
    { label: "Khanewal", value: "khanewal" },
    { label: "Khushab", value: "khushab" },
    { label: "Kot Addu", value: "kot_addu" },
    { label: "Lahore", value: "lahore" },
    { label: "Layyah", value: "layyah" },
    { label: "Lodhran", value: "lodhran" },
    { label: "Mandi Bahauddin", value: "mandi_bahauddin" },
    { label: "Mianwali", value: "mianwali" },
    { label: "Multan", value: "multan" },
    { label: "Muzaffargarh", value: "muzaffargarh" },
    { label: "Narowal", value: "narowal" },
    { label: "Nankana Sahib", value: "nankana_sahib" },
    { label: "Okara", value: "okara" },
    { label: "Pakpattan", value: "pakpattan" },
    { label: "Rahim Yar Khan", value: "rahim_yar_khan" },
    { label: "Rajanpur", value: "rajanpur" },
    { label: "Rawalpindi", value: "rawalpindi" },
    { label: "Sahiwal", value: "sahiwal" },
    { label: "Sargodha", value: "sargodha" },
    { label: "Sheikhupura", value: "sheikhupura" },
    { label: "Sialkot", value: "sialkot" },
    { label: "Toba Tek Singh", value: "toba_tek_singh" },
    { label: "Vehari", value: "vehari" },
  ],
  tehsils_attock: [
    { label: "Attock", value: "attock" },
    { label: "Fateh Jang", value: "fateh jang" },
    { label: "Hassan Abdal", value: "hassan abdal" },
    { label: "Hazro", value: "hazro" },
    { label: "Jand", value: "jand" },
    { label: "Pindi Gheb", value: "pindi gheb" },
  ],
  tehsils_bahawalnagar: [
    { label: "Bahawalnagar", value: "bahawalnagar" },
    { label: "Chishtian", value: "chishtian" },
    { label: "Fort Abbas", value: "fort abbas" },
    { label: "Haroonabad", value: "haroonabad" },
    { label: "Minchinabad", value: "minchinabad" },
  ],
  tehsils_bahawalpur: [
    { label: "Ahmadpur East", value: "ahmadpur east" },
    { label: "Bahawalpur City", value: "bahawalpur city" },
    { label: "Bahawalpur Saddar", value: "bahawalpur saddar" },
    { label: "Hasilpur", value: "hasilpur" },
    { label: "Khairpur Tamewali", value: "khairpur tamewali" },
    { label: "Yazman", value: "yazman" },
  ],
  tehsils_bhakkar: [
    { label: "Bhakkar", value: "bhakkar" },
    { label: "Darya Khan", value: "darya khan" },
    { label: "Kaloorkot", value: "kaloorkot" },
    { label: "Mankera", value: "mankera" },
  ],
  tehsils_chakwal: [
    { label: "Chakwal", value: "chakwal" },
    { label: "Choa Saidan Shah", value: "choa saidan shah" },
    { label: "Kallar Kahar", value: "kallar kahar" },
  ],
  tehsils_chiniot: [
    { label: "Bhowana", value: "bhowana" },
    { label: "Chiniot", value: "chiniot" },
    { label: "Lalian", value: "lalian" },
  ],
  tehsils_dera_ghazi_khan: [
    { label: "Dera Ghazi Khan", value: "dera ghazi khan" },
    { label: "Kot Chutta", value: "kot chutta" },
  ],
  tehsils_faisalabad: [
    { label: "Chak Jhumra", value: "chak jhumra" },
    { label: "Faisalabad City", value: "faisalabad city" },
    { label: "Faisalabad Saddar", value: "faisalabad sadar" },
    { label: "Jaranwala", value: "jaranwala" },
    { label: "Samundri", value: "samundri" },
    { label: "Tandlianwala", value: "tandlianwala" },
  ],
  tehsils_gujranwala: [
    { label: "Gujranwala City", value: "gujranwala city" },
    { label: "Gujranwala Saddar", value: "gujranwala saddar" },
    { label: "Kamoke", value: "kamoke" },
    { label: "Nowshera Virkan", value: "nowshera virkan" },
  ],
  tehsils_gujrat: [
    { label: "Gujrat", value: "gujrat" },
    { label: "Jalalpur Jattan", value: "jalalpur jattan" },
    { label: "Kharian", value: "kharian" },
    { label: "Kunjah", value: "kunjah" },
    { label: "Sarai Alamgir", value: "sarai alamgir" },
  ],
  tehsils_hafizabad: [
    { label: "Hafizabad", value: "hafizabad" },
    { label: "Pindi Bhattian", value: "pindi bhattian" },
  ],
  tehsils_jampur: [
    { label: "Dajal", value: "dajal" },
    { label: "Jampur", value: "jampur" },
    { label: "Muhammadpur", value: "muhammadpur" },
    { label: "Tribal Area", value: "tribal area" },
  ],
  tehsils_jhang: [
    { label: "Ahmadpur Sial", value: "ahmadpur sial" },
    { label: "Athara Hazari", value: "athara hazari" },
    { label: "Jhang", value: "jhang" },
    { label: "Mandi Shah Jeewna", value: "mandi shah jeewna" },
    { label: "Shorkot", value: "shorkot" },
  ],
  tehsils_jhelum: [
    { label: "Dina", value: "dina" },
    { label: "Jhelum", value: "jhelum" },
    { label: "Pind Dadan Khan", value: "pind dadan khan" },
    { label: "Sohawa", value: "sohawa" },
  ],
  tehsils_kasur: [
    { label: "Chunian", value: "chunian" },
    { label: "Kasur", value: "kasur" },
    { label: "Kot Radha Kishan", value: "kot radha kishan" },
    { label: "Pattoki", value: "pattoki" },
  ],
  tehsils_khanewal: [
    { label: "Jahanian", value: "jahanian" },
    { label: "Kabirwala", value: "kabirwala" },
    { label: "Khanewal", value: "khanewal" },
    { label: "Mian Channu", value: "mian channu" },
    { label: "Rahimyaar Khan", value: "rahimyaar khan" },
  ],
  tehsils_khushab: [
    { label: "Khushab", value: "khushab" },
    { label: "Naushera(Wadi-e-Soon)", value: "naushera(wadi-e-soon)" },
    { label: "Noorpur Thal", value: "noorpur thal" },
    { label: "Quaidabad", value: "quaidabad" },
  ],
  tehsils_kot_addu: [
    { label: "Chowk Sarwar Shaheed", value: "chowk sarwar shaheed" },
    { label: "Kot Addu", value: "kot addu" },
  ],
  tehsils_lahore: [
    { label: "Lahore Cantonment", value: "lahore cantonment" },
    { label: "Lahore City", value: "lahore city" },
    { label: "Model Town", value: "model town" },
    { label: "Raiwind", value: "raiwind" },
    { label: "Shalimar", value: "shalimar" },
  ],
  tehsils_layyah: [
    { label: "Chaubara", value: "chaubara" },
    { label: "Karor Lal Esan", value: "karor lal esan" },
    { label: "Layyah", value: "layyah" },
  ],
  tehsils_lodhran: [
    { label: "Dunyapur", value: "dunyapur" },
    { label: "Kahror Pacca", value: "kahror pacca" },
    { label: "Lodhran", value: "lodhran" },
  ],
  tehsils_mandi_bahauddin: [
    { label: "Malakwal", value: "malakwal" },
    { label: "Mandi Bahauddin", value: "mandi bahauddin" },
    { label: "Phalia", value: "phalia" },
  ],
  tehsils_mianwali: [
    { label: "Isakhel", value: "isakhel" },
    { label: "Mianwali", value: "mianwali" },
    { label: "Piplan", value: "piplan" },
  ],
  tehsils_multan: [
    { label: "Jalalpur Pirwala", value: "jalalpur pirwala" },
    { label: "Multan City", value: "multan city" },
    { label: "Multan Saddar", value: "multan saddar" },
    { label: "Shujabad", value: "shujabad" },
  ],
  tehsils_murree: [
    { label: "Kotli Sattian", value: "kotli sattian" },
    { label: "Murree", value: "murree" },
  ],
  tehsils_muzaffargarh: [
    { label: "Alipur", value: "alipur" },
    { label: "Jatoi", value: "jatoi" },
    { label: "Muzaffargarh", value: "muzaffargarh" },
  ],
  tehsils_nankana_sahib: [
    { label: "Nankana Sahib", value: "nankana sahib" },
    { label: "Sangla Hill", value: "sangla hill" },
    { label: "Shah Kot", value: "shah kot" },
  ],
  tehsils_narowal: [
    { label: "Narowal", value: "narowal" },
    { label: "Shakargarh", value: "shakargarh" },
    { label: "Zafarwal", value: "zafarwal" },
  ],
  tehsils_okara: [
    { label: "Depalpur", value: "depalpur" },
    { label: "Okara", value: "okara" },
    { label: "Renala Khurd", value: "renala khurd" },
  ],
  tehsils_pakpattan: [
    { label: "Arifwala", value: "arifwala" },
    { label: "Pakpattan", value: "pakpattan" },
  ],
  tehsils_rahim_yar_khan: [
    { label: "Khanpur", value: "khanpur" },
    { label: "Liaqatpur", value: "liaqatpur" },
    { label: "Rahim Yar Khan", value: "rahim yar khan" },
    { label: "Sadiqabad", value: "sadiqabad" },
  ],
  tehsils_rajanpur: [
    { label: "Fazilpur", value: "fazilpur" },
    { label: "Jampur", value: "jampur" },
    { label: "Rojhan", value: "rojhan" },
  ],
  tehsils_rawalpindi: [
    { label: "Daultala", value: "daultala" },
    { label: "Gujar Khan", value: "gujar khan" },
    { label: "Kahuta", value: "kahuta" },
    { label: "Kallar Syedan", value: "kallar syedan" },
    { label: "Rawalpindi", value: "rawalpindi" },
    { label: "Taxila", value: "taxila" },
  ],
  tehsils_sahiwal: [
    { label: "Chichawatni", value: "chichawatni" },
    { label: "Sahiwal", value: "sahiwal" },
  ],
  tehsils_sargodha: [
    { label: "Bhalwal", value: "bhalwal" },
    { label: "Bhera", value: "bhera" },
    { label: "Kot Momin", value: "kot momin" },
    { label: "Sahiwal", value: "sahiwal" },
    { label: "Sargodha", value: "sargodha" },
    { label: "Shahpur", value: "shahpur" },
    { label: "Sillanwali", value: "sillanwali" },
  ],
  tehsils_sheikhupura: [
    { label: "Ferozewala", value: "ferozewala" },
    { label: "Muridke", value: "muridke" },
    { label: "Safdarabad", value: "safdarabad" },
    { label: "Sharak Pur", value: "sharak pur" },
    { label: "Sheikhupura", value: "sheikhupura" },
  ],
  tehsils_sialkot: [
    { label: "Daska", value: "daska" },
    { label: "Pasrur", value: "pasrur" },
    { label: "Sambrial", value: "sambrial" },
    { label: "Sialkot", value: "sialkot" },
  ],
  tehsils_talagang: [
    { label: "Lawa", value: "lawa" },
    { label: "Multan Khurd", value: "multan khurd" },
    { label: "Talagang", value: "talagang" },
  ],
  tehsils_taunsa: [
    { label: "Koh-e-Suleman", value: "koh-e-suleman" },
    { label: "Taunsa", value: "taunsa" },
    { label: "Vehova", value: "vehova" },
  ],
  tehsils_toba_tek_singh: [
    { label: "Gojra", value: "gojra" },
    { label: "Kamalia", value: "kamalia" },
    { label: "Pirmahal", value: "pirmahal" },
    { label: "Toba Tek Singh", value: "toba tek singh" },
  ],
  tehsils_vehari: [
    { label: "Burewala", value: "burewala" },
    { label: "Jallah Jeem", value: "jallah jeem" },
    { label: "Mailsi", value: "mailsi" },
    { label: "Vehari", value: "vehari" },
  ],
  tehsils_wazirabad: [
    { label: "Ali Pur Chatta", value: "ali pur chatta" },
    { label: "Wazirabad", value: "wazirabad" },
  ],
  districts_sindh: [
    { label: "Badin", value: "badin" },
    { label: "Dadu", value: "dadu" },
    { label: "Ghotki", value: "ghotki" },
    { label: "Hyderabad", value: "hyderabad" },
    { label: "Jacobabad", value: "jacobabad" },
    { label: "Jamshoro", value: "jamshoro" },
    { label: "Karachi Central", value: "karachi_central" },
    { label: "Karachi East", value: "karachi_east" },
    { label: "Karachi South", value: "karachi_south" },
    { label: "Karachi West", value: "karachi_west" },
    { label: "Korangi", value: "korangi" },
    { label: "Kashmore", value: "kashmore" },
    { label: "Khairpur", value: "khairpur" },
    { label: "Larkana", value: "larkana" },
    { label: "Malir", value: "malir" },
    { label: "Mirpur Khas", value: "mirpur_khas" },
    { label: "Naushahro Feroze", value: "naushahro_feroze" },
    { label: "Qambar Shahdadkot", value: "qambar_shahdadkot" },
    { label: "Sanghar", value: "sanghar" },
    { label: "Shaheed Benazirabad", value: "shaheed_benazirabad" },
    { label: "Shikarpur", value: "shikarpur" },
    { label: "Sujawal", value: "sujawal" },
    { label: "Sukkur", value: "sukkur" },
    { label: "Tando Allahyar", value: "tando_allahyar" },
    { label: "Tando Muhammad Khan", value: "tando_muhammad_khan" },
    { label: "Tharparkar", value: "tharparkar" },
    { label: "Thatta", value: "thatta" },
    { label: "Umerkot", value: "umerkot" },
  ],
  tehsils_badin: [
    { label: "Badin", value: "badin" },
    { label: "Khoski", value: "khoski" },
    { label: "Matli", value: "matli" },
    { label: "Shaheed Fazil Rahu", value: "shaheed fazil rahu" },
    { label: "Talhar", value: "talhar" },
    { label: "Tando Bago", value: "tando bago" },
  ],
  tehsils_dadu: [
    { label: "Dadu", value: "dadu" },
    { label: "Johi", value: "johi" },
    { label: "Khairpur Nathan Shah", value: "khairpur nathan shah" },
    { label: "Mehar", value: "mehar" },
  ],
  tehsils_ghotki: [
    { label: "Daharki", value: "daharki" },
    { label: "Ghotki", value: "ghotki" },
    { label: "Khangarh (Khanpur)", value: "khangarh (khanpur)" },
    { label: "Mirpur Mathelo", value: "mirpur mathelo" },
    { label: "Ubauro", value: "ubauro" },
  ],
  tehsils_hyderabad: [
    { label: "Hyderabad", value: "hyderabad" },
    { label: "Hyderabad City", value: "hyderabad city" },
    { label: "Latifabad", value: "latifabad" },
    { label: "Qasimabad", value: "qasimabad" },
  ],
  tehsils_jacobabad: [
    { label: "Garhi Khairo", value: "garhi khairo" },
    { label: "Jacobabad", value: "jacobabad" },
    { label: "Thul", value: "thul" },
  ],
  tehsils_jamshoro: [
    { label: "Kotri", value: "kotri" },
    { label: "Manjhand", value: "manjhand" },
    { label: "Sehwan", value: "sehwan" },
    { label: "Thana Bulla Khan", value: "thana bulla khan" },
  ],
  tehsils_karachi_central: [
    { label: "Gulberg Town", value: "gulberg town" },
    { label: "Liaquatabad Town", value: "liaquatabad town" },
    { label: "Nazimabad", value: "nazimabad" },
    { label: "New Karachi Town", value: "new karachi town" },
    { label: "North Karachi", value: "north karachi" },
    { label: "North Nazimabad Town", value: "north nazimabad town" },
  ],
  tehsils_karachi_east: [
    { label: "Ferozabad", value: "ferozabad" },
    { label: "Gulistan-e-Jouhar (G-E-J)", value: "gulistan-e-jouhar (g-e-j)" },
    { label: "Gulshan-e-Iqbal", value: "gulshan-e-iqbal" },
    { label: "Gulshan Town", value: "gulshan town" },
    { label: "Gulzar-e-Hijri", value: "gulzar-e-hijri" },
    { label: "Jamshed Town", value: "jamshed town" },
  ],
  tehsils_karachi_south: [
    { label: "Aram Bagh", value: "aram bagh" },
    { label: "Civil Line", value: "civil line" },
    { label: "Garden", value: "garden" },
    { label: "Lyari Town", value: "lyari town" },
    { label: "Saddar Town", value: "saddar town" },
  ],
  tehsils_karachi_west: [
    { label: "Baldia Town", value: "baldia town" },
    { label: "Ittehad Town", value: "ittehad town" },
    { label: "Manghopir", value: "manghopir" },
    { label: "Maripur", value: "maripur" },
    { label: "Mominabad", value: "mominabad" },
    { label: "Orangi Town", value: "orangi town" },
  ],
  tehsils_kashmore: [
    { label: "Kandhkot", value: "kandhkot" },
    { label: "Kashmore", value: "kashmore" },
    { label: "Tangwani", value: "tangwani" },
  ],
  tehsils_keamari: [
    { label: "Baldia Town", value: "baldia town" },
    { label: "Karachi Fish Harbour", value: "karachi fish harbour" },
    { label: "Keamari Town", value: "keamari town" },
    { label: "S.I.T.E. Town", value: "s.i.t.e. town" },
  ],
  tehsils_khairpur_mirs: [
    { label: "Faiz Ganj", value: "faiz ganj" },
    { label: "Gambat", value: "gambat" },
    { label: "Khairpur", value: "khairpur" },
    { label: "Kingri", value: "kingri" },
    { label: "Kot Diji", value: "kot diji" },
    { label: "Nara", value: "nara" },
    { label: "Sobho Dero", value: "sobho dero" },
    { label: "Thari Mirwah", value: "thari mirwah" },
  ],
  tehsils_korangi: [
    { label: "Korangi Town", value: "korangi town" },
    { label: "Landhi Town", value: "landhi town" },
    { label: "Model Colony", value: "model colony" },
    { label: "Shah Faisal Town", value: "shah faisal town" },
  ],
  tehsils_larkana: [
    { label: "Bakrani", value: "bakrani" },
    { label: "Dokri", value: "dokri" },
    { label: "Larkana", value: "larkana" },
    { label: "Ratodero", value: "ratodero" },
  ],
  tehsils_malir: [
    { label: "Bin Qasim Town", value: "bin qasim town" },
    { label: "Gadap Town", value: "gadap town" },
    { label: "Ibrahim Hyderi", value: "ibrahim hyderi" },
    { label: "Jinnah", value: "jinnah" },
    { label: "Malir Town", value: "malir town" },
    { label: "Murad Memon", value: "murad memon" },
    { label: "Shah Murad", value: "shah murad" },
  ],
  tehsils_matiari: [
    { label: "Hala", value: "hala" },
    { label: "Matiari", value: "matiari" },
    { label: "Saeedabad", value: "saeedabad" },
  ],
  tehsils_mirpur_khas: [
    { label: "Digri", value: "digri" },
    { label: "Jhuddo", value: "jhuddo" },
    { label: "Kot Ghulam Muhammad", value: "kot ghulam muhammad" },
    { label: "Mirpur Khas", value: "mirpur khas" },
    { label: "Shujabad", value: "shujabad" },
    { label: "Sindhri", value: "sindhri" },
  ],
  tehsils_naushahro_feroze: [
    { label: "Bhiria", value: "bhiria" },
    { label: "Kandiaro", value: "kandiaro" },
    { label: "Mehrabpur", value: "mehrabpur" },
    { label: "Moro", value: "moro" },
    { label: "Naushahro Feroze", value: "naushahro feroze" },
  ],
  tehsils_qambar_shahdadkot: [
    { label: "Mirokhan", value: "mirokhan" },
    { label: "Nasirabad", value: "nasirabad" },
    { label: "Qambar", value: "qambar" },
    { label: "Qubo Saeed Khan", value: "qubo saeed khan" },
    { label: "Shahdadkot", value: "shahdadkot" },
    { label: "Sijawal Junejo", value: "sijawal junejo" },
    { label: "Warah", value: "warah" },
  ],
  tehsils_sanghar: [
    { label: "Jam Nawaz Ali", value: "jam nawaz ali" },
    { label: "Khipro", value: "khipro" },
    { label: "Sanghar", value: "sanghar" },
    { label: "Shahdadpur", value: "shahdadpur" },
    { label: "Sinjhoro", value: "sinjhoro" },
    { label: "Tando Adam Khan", value: "tando adam khan" },
  ],
  tehsils_shaheed_benazir_abad: [
    { label: "Daur", value: "daur" },
    { label: "Doulatpur", value: "doulatpur" },
    { label: "Nawabshah", value: "nawabshah" },
    { label: "Qazi Ahmed", value: "qazi ahmed" },
    { label: "Sakrand", value: "sakrand" },
  ],
  tehsils_shikarpur: [
    { label: "Garhi Yasin", value: "garhi yasin" },
    { label: "Khanpur", value: "khanpur" },
    { label: "Lakhi", value: "lakhi" },
    { label: "Shikarpur", value: "shikarpur" },
  ],
  tehsils_sujawal: [
    { label: "Jati", value: "jati" },
    { label: "Kharo Chan", value: "kharo chan" },
    { label: "Mirpur Bathoro", value: "mirpur bathoro" },
    { label: "Shah Bandar", value: "shah bandar" },
    { label: "Sujawal", value: "sujawal" },
  ],
  tehsils_sukkur: [
    { label: "New Sukkur", value: "new sukkur" },
    { label: "Pano Aqil", value: "pano aqil" },
    { label: "Rohri", value: "rohri" },
    { label: "Salehpat", value: "salehpat" },
    { label: "Sukkur", value: "sukkur" },
  ],
  tehsils_tando_allahyar: [
    { label: "Chamber", value: "chamber" },
    { label: "Jhando Mari", value: "jhando mari" },
    { label: "Nasarpur", value: "nasarpur" },
    { label: "Tando Allahyar", value: "tando allahyar" },
  ],
  tehsils_tando_muhammad_khan: [
    { label: "Bulri Shah Karim", value: "bulri shah karim" },
    { label: "Tando Ghulam Hyder", value: "tando ghulam hyder" },
    { label: "Tando Mohammad Khan", value: "tando mohammad khan" },
  ],
  tehsils_tharparkar: [
    { label: "Chachro", value: "chachro" },
    { label: "Dahli", value: "dahli" },
    { label: "Diplo", value: "diplo" },
    { label: "Islamkot", value: "islamkot" },
    { label: "Kaloi", value: "kaloi" },
    { label: "Mithi", value: "mithi" },
    { label: "Nagarparkar", value: "nagarparkar" },
  ],
  tehsils_thatta: [
    { label: "Ghorabari", value: "ghorabari" },
    { label: "Keti Bunder", value: "keti bunder" },
    { label: "Mirpur Sakro", value: "mirpur sakro" },
    { label: "Thatta", value: "thatta" },
  ],
  tehsils_umerkot: [
    { label: "Kunri", value: "kunri" },
    { label: "Pithoro", value: "pithoro" },
    { label: "Samaro", value: "samaro" },
    { label: "Umerkot", value: "umerkot" },
  ],
};

export const districtsOption = [
  { label: "Attock", value: "Attock" },
  { label: "Bahawalnagar", value: "Bahawalnagar" },
  { label: "Bahawalpur", value: "Bahawalpur" },
  { label: "Bhakkar", value: "Bhakkar" },
  { label: "Chakwal", value: "Chakwal" },
  { label: "Chiniot", value: "Chiniot" },
  { label: "Dera Ghazi Khan", value: "Dera Ghazi Khan" },
  { label: "Faisalabad", value: "Faisalabad" },
  { label: "Gujranwala", value: "Gujranwala" },
  { label: "Gujrat", value: "Gujrat" },
  { label: "Hafizabad", value: "Hafizabad" },
  { label: "Jhang", value: "Jhang" },
  { label: "Jhelum", value: "Jhelum" },
  { label: "Kasur", value: "Kasur" },
  { label: "Khanewal", value: "Khanewal" },
  { label: "Khushab", value: "Khushab" },
  { label: "Lahore", value: "Lahore" },
  { label: "Layyah", value: "Layyah" },
  { label: "Lodhran", value: "Lodhran" },
  { label: "Mandi Bahauddin", value: "Mandi Bahauddin" },
  { label: "Mianwali", value: "Mianwali" },
  { label: "Multan", value: "Multan" },
  { label: "Muzaffargarh", value: "Muzaffargarh" },
  { label: "Nankana Sahib", value: "Nankana Sahib" },
  { label: "Narowal", value: "Narowal" },
  { label: "Okara", value: "Okara" },
  { label: "Pakpattan", value: "Pakpattan" },
  { label: "Rahim Yar Khan", value: "Rahim Yar Khan" },
  { label: "Rajanpur", value: "Rajanpur" },
  { label: "Rawalpindi", value: "Rawalpindi" },
  { label: "Sahiwal", value: "Sahiwal" },
  { label: "Sargodha", value: "Sargodha" },
  { label: "Sheikhupura", value: "Sheikhupura" },
  { label: "Sialkot", value: "Sialkot" },
  { label: "Toba Tek Singh", value: "Toba Tek Singh" },
  { label: "Vehari", value: "Vehari" },
  { label: "Badin", value: "Badin" },
  { label: "Dadu", value: "Dadu" },
  { label: "Ghotki", value: "Ghotki" },
  { label: "Hyderabad", value: "Hyderabad" },
  { label: "Jacobabad", value: "Jacobabad" },
  { label: "Jamshoro", value: "Jamshoro" },
  { label: "Karachi Central", value: "Karachi Central" },
  { label: "Karachi East", value: "Karachi East" },
  { label: "Karachi South", value: "Karachi South" },
  { label: "Karachi West", value: "Karachi West" },
  { label: "Kashmore", value: "Kashmore" },
  { label: "Khairpur", value: "Khairpur" },
  { label: "Larkana", value: "Larkana" },
  { label: "Matiari", value: "Matiari" },
  { label: "Mirpur Khas", value: "Mirpur Khas" },
  { label: "Naushahro Firoz", value: "Naushahro Firoz" },
  { label: "Qambar Shahdadkot", value: "Qambar Shahdadkot" },
  { label: "Sanghar", value: "Sanghar" },
  { label: "Shikarpur", value: "Shikarpur" },
  { label: "Sukkur", value: "Sukkur" },
  { label: "Tando Allahyar", value: "Tando Allahyar" },
  { label: "Tando Muhammad Khan", value: "Tando Muhammad Khan" },
  { label: "Tharparkar", value: "Tharparkar" },
  { label: "Thatta", value: "Thatta" },
  { label: "Umerkot", value: "Umerkot" },
  { label: "Abbottabad", value: "Abbottabad" },
  { label: "Bannu", value: "Bannu" },
  { label: "Batagram", value: "Batagram" },
  { label: "Buner", value: "Buner" },
  { label: "Charsadda", value: "Charsadda" },
  { label: "Chitral", value: "Chitral" },
  { label: "Dera Ismail Khan", value: "Dera Ismail Khan" },
  { label: "Hangu", value: "Hangu" },
  { label: "Haripur", value: "Haripur" },
  { label: "Karak", value: "Karak" },
  { label: "Kohat", value: "Kohat" },
  { label: "Kohistan", value: "Kohistan" },
  { label: "Lakki Marwat", value: "Lakki Marwat" },
  { label: "Lower Dir", value: "Lower Dir" },
  { label: "Malakand", value: "Malakand" },
  { label: "Mansehra", value: "Mansehra" },
  { label: "Mardan", value: "Mardan" },
  { label: "Nowshera", value: "Nowshera" },
  { label: "Peshawar", value: "Peshawar" },
  { label: "Shangla", value: "Shangla" },
  { label: "Swabi", value: "Swabi" },
  { label: "Swat", value: "Swat" },
  { label: "Tank", value: "Tank" },
  { label: "Tor Ghar", value: "Tor Ghar" },
  { label: "Upper Dir", value: "Upper Dir" },
  { label: "Awaran", value: "Awaran" },
  { label: "Barkhan", value: "Barkhan" },
  { label: "Bolan", value: "Bolan" },
  { label: "Chagai", value: "Chagai" },
  { label: "Dera Bugti", value: "Dera Bugti" },
  { label: "Gwadar", value: "Gwadar" },
  { label: "Harnai", value: "Harnai" },
  { label: "Jafarabad", value: "Jafarabad" },
  { label: "Jhal Magsi", value: "Jhal Magsi" },
  { label: "Kalat", value: "Kalat" },
  { label: "Kech", value: "Kech" },
  { label: "Kharan", value: "Kharan" },
  { label: "Khuzdar", value: "Khuzdar" },
  { label: "Killa Abdullah", value: "Killa Abdullah" },
  { label: "Killa Saifullah", value: "Killa Saifullah" },
  { label: "Kohlu", value: "Kohlu" },
  { label: "Lasbela", value: "Lasbela" },
  { label: "Loralai", value: "Loralai" },
  { label: "Mastung", value: "Mastung" },
  { label: "Musakhel", value: "Musakhel" },
  { label: "Naseerabad", value: "Naseerabad" },
  { label: "Nushki", value: "Nushki" },
  { label: "Panjgur", value: "Panjgur" },
  { label: "Pishin", value: "Pishin" },
  { label: "Quetta", value: "Quetta" },
  { label: "Sherani", value: "Sherani" },
  { label: "Sibi", value: "Sibi" },
  { label: "Sohbatpur", value: "Sohbatpur" },
  { label: "Washuk", value: "Washuk" },
  { label: "Zhob", value: "Zhob" },
  { label: "Ziarat", value: "Ziarat" },
  { label: "Astore", value: "Astore" },
  { label: "Diamer", value: "Diamer" },
  { label: "Ghanche", value: "Ghanche" },
  { label: "Gilgit", value: "Gilgit" },
  { label: "Hunza", value: "Hunza" },
  { label: "Kharmang", value: "Kharmang" },
  { label: "Nagar", value: "Nagar" },
  { label: "Shigar", value: "Shigar" },
  { label: "Shangla", value: "Shangla" },
  { label: "Skardu", value: "Skardu" },
  { label: "Bagh", value: "Bagh" },
  { label: "Bhimber", value: "Bhimber" },
  { label: "Hattian", value: "Hattian" },
  { label: "Haveli", value: "Haveli" },
  { label: "Kotli", value: "Kotli" },
  { label: "Mirpur", value: "Mirpur" },
  { label: "Muzaffarabad", value: "Muzaffarabad" },
  { label: "Neelum", value: "Neelum" },
  { label: "Poonch", value: "Poonch" },
  { label: "Sudhanoti", value: "Sudhanoti" },
];

export const citiesOptions = [
  { label: "Islamabad", value: "Islamabad" },
  { label: "Ahmed Nager", value: "Ahmed Nager" },
  { label: "Ahmadpur East", value: "Ahmadpur East" },
  { label: "Ali Khan", value: "Ali Khan" },
  { label: "Alipur", value: "Alipur" },
  { label: "Arifwala", value: "Arifwala" },
  { label: "Attock", value: "Attock" },
  { label: "Bhera", value: "Bhera" },
  { label: "Bhalwal", value: "Bhalwal" },
  { label: "Bahawalnagar", value: "Bahawalnagar" },
  { label: "Bahawalpur", value: "Bahawalpur" },
  { label: "Bhakkar", value: "Bhakkar" },
  { label: "Burewala", value: "Burewala" },
  { label: "Chillianwala", value: "Chillianwala" },
  { label: "Chakwal", value: "Chakwal" },
  { label: "Chichawatni", value: "Chichawatni" },
  { label: "Chiniot", value: "Chiniot" },
  { label: "Chishtian", value: "Chishtian" },
  { label: "Daska", value: "Daska" },
  { label: "Darya Khan", value: "Darya Khan" },
  { label: "Dera Ghazi", value: "Dera Ghazi" },
  { label: "Dhaular", value: "Dhaular" },
  { label: "Dina", value: "Dina" },
  { label: "Dinga", value: "Dinga" },
  { label: "Dipalpur", value: "Dipalpur" },
  { label: "Faisalabad", value: "Faisalabad" },
  { label: "Fateh Jhang", value: "Fateh Jhang" },
  { label: "Ghakhar Mandi", value: "Ghakhar Mandi" },
  { label: "Gojra", value: "Gojra" },
  { label: "Gujranwala", value: "Gujranwala" },
  { label: "Gujrat", value: "Gujrat" },
  { label: "Gujar Khan", value: "Gujar Khan" },
  { label: "Hafizabad", value: "Hafizabad" },
  { label: "Haroonabad", value: "Haroonabad" },
  { label: "Hasilpur", value: "Hasilpur" },
  { label: "Haveli", value: "Haveli" },
  { label: "Lakha", value: "Lakha" },
  { label: "Jalalpur", value: "Jalalpur" },
  { label: "Jattan", value: "Jattan" },
  { label: "Jampur", value: "Jampur" },
  { label: "Jaranwala", value: "Jaranwala" },
  { label: "Jhang", value: "Jhang" },
  { label: "Jhelum", value: "Jhelum" },
  { label: "Kalabagh", value: "Kalabagh" },
  { label: "Karor Lal", value: "Karor Lal" },
  { label: "Kasur", value: "Kasur" },
  { label: "Kamalia", value: "Kamalia" },
  { label: "Kamoke", value: "Kamoke" },
  { label: "Khanewal", value: "Khanewal" },
  { label: "Khanpur", value: "Khanpur" },
  { label: "Kharian", value: "Kharian" },
  { label: "Khushab", value: "Khushab" },
  { label: "Kot Adu", value: "Kot Adu" },
  { label: "Jauharabad", value: "Jauharabad" },
  { label: "Lahore", value: "Lahore" },
  { label: "Lalamusa", value: "Lalamusa" },
  { label: "Layyah", value: "Layyah" },
  { label: "Liaquat Pur", value: "Liaquat Pur" },
  { label: "Lodhran", value: "Lodhran" },
  { label: "Malakwal", value: "Malakwal" },
  { label: "Mamoori", value: "Mamoori" },
  { label: "Mailsi", value: "Mailsi" },
  { label: "Mandi Bahauddin", value: "Mandi Bahauddin" },
  { label: "Mian Channu", value: "Mian Channu" },
  { label: "Mianwali", value: "Mianwali" },
  { label: "Multan", value: "Multan" },
  { label: "Murree", value: "Murree" },
  { label: "Muridke", value: "Muridke" },
  { label: "Mianwali Bangla", value: "Mianwali Bangla" },
  { label: "Muzaffargarh", value: "Muzaffargarh" },
  { label: "Narowal", value: "Narowal" },
  { label: "Okara", value: "Okara" },
  { label: "Renala Khurd", value: "Renala Khurd" },
  { label: "Pakpattan", value: "Pakpattan" },
  { label: "Pattoki", value: "Pattoki" },
  { label: "Pir Mahal", value: "Pir Mahal" },
  { label: "Qaimpur", value: "Qaimpur" },
  { label: "Qila Didar", value: "Qila Didar" },
  { label: "Rabwah", value: "Rabwah" },
  { label: "Raiwind", value: "Raiwind" },
  { label: "Rajanpur", value: "Rajanpur" },
  { label: "Rahim Yar", value: "Rahim Yar" },
  { label: "Rawalpindi", value: "Rawalpindi" },
  { label: "Sadiqabad", value: "Sadiqabad" },
  { label: "Safdarabad", value: "Safdarabad" },
  { label: "Sahiwal", value: "Sahiwal" },
  { label: "Sangla Hill", value: "Sangla Hill" },
  { label: "Sarai Alamgir", value: "Sarai Alamgir" },
  { label: "Sargodha", value: "Sargodha" },
  { label: "Shakargarh", value: "Shakargarh" },
  { label: "Sheikhupura", value: "Sheikhupura" },
  { label: "Sialkot", value: "Sialkot" },
  { label: "Sohawa", value: "Sohawa" },
  { label: "Soianwala", value: "Soianwala" },
  { label: "Siranwali", value: "Siranwali" },
  { label: "Talagang", value: "Talagang" },
  { label: "Taxila", value: "Taxila" },
  { label: "Toba Tek", value: "Toba Tek" },
  { label: "Vehari", value: "Vehari" },
  { label: "Wah Cantonment", value: "Wah Cantonment" },
  { label: "Wazirabad", value: "Wazirabad" },
  { label: "Badin", value: "Badin" },
  { label: "Bhirkan", value: "Bhirkan" },
  { label: "Rajo Khanani", value: "Rajo Khanani" },
  { label: "Chak", value: "Chak" },
  { label: "Dadu", value: "Dadu" },
  { label: "Digri", value: "Digri" },
  { label: "Diplo", value: "Diplo" },
  { label: "Dokri", value: "Dokri" },
  { label: "Ghotki", value: "Ghotki" },
  { label: "Haala", value: "Haala" },
  { label: "Hyderabad", value: "Hyderabad" },
  { label: "Islamkot", value: "Islamkot" },
  { label: "Jacobabad", value: "Jacobabad" },
  { label: "Jamshoro", value: "Jamshoro" },
  { label: "Jungshahi", value: "Jungshahi" },
  { label: "Kandhkot", value: "Kandhkot" },
  { label: "Kandiaro", value: "Kandiaro" },
  { label: "Karachi", value: "Karachi" },
  { label: "Kashmore", value: "Kashmore" },
  { label: "Keti Bandar", value: "Keti Bandar" },
  { label: "Khairpur", value: "Khairpur" },
  { label: "Kotri", value: "Kotri" },
  { label: "Larkana", value: "Larkana" },
  { label: "Matiari", value: "Matiari" },
  { label: "Mehar", value: "Mehar" },
  { label: "Mirpur Khas", value: "Mirpur Khas" },
  { label: "Mithani", value: "Mithani" },
  { label: "Mithi", value: "Mithi" },
  { label: "Mehrabpur", value: "Mehrabpur" },
  { label: "Moro", value: "Moro" },
  { label: "Nagarparkar", value: "Nagarparkar" },
  { label: "Naudero", value: "Naudero" },
  { label: "Naushahro Feroze", value: "Naushahro Feroze" },
  { label: "Naushara", value: "Naushara" },
  { label: "Nawabshah", value: "Nawabshah" },
  { label: "Nazimabad", value: "Nazimabad" },
  { label: "Qambar", value: "Qambar" },
  { label: "Qasimabad", value: "Qasimabad" },
  { label: "Ranipur", value: "Ranipur" },
  { label: "Ratodero", value: "Ratodero" },
  { label: "Rohri", value: "Rohri" },
  { label: "Sakrand", value: "Sakrand" },
  { label: "Sanghar", value: "Sanghar" },
  { label: "Shahbandar", value: "Shahbandar" },
  { label: "Shahdadkot", value: "Shahdadkot" },
  { label: "Shahdadpur", value: "Shahdadpur" },
  { label: "Shahpur Chakar", value: "Shahpur Chakar" },
  { label: "Shikarpaur", value: "Shikarpaur" },
  { label: "Sukkur", value: "Sukkur" },
  { label: "Tangwani", value: "Tangwani" },
  { label: "Tando Adam", value: "Tando Adam" },
  { label: "Tando Allahyar", value: "Tando Allahyar" },
  { label: "Tando Muhammad", value: "Tando Muhammad" },
  { label: "Thatta", value: "Thatta" },
  { label: "Umerkot", value: "Umerkot" },
  { label: "Warah", value: "Warah" },
  { label: "Abbottabad", value: "Abbottabad" },
  { label: "Adezai", value: "Adezai" },
  { label: "Alpuri", value: "Alpuri" },
  { label: "Akora Khattak", value: "Akora Khattak" },
  { label: "Ayubia", value: "Ayubia" },
  { label: "Banda Daud", value: "Banda Daud" },
  { label: "Bannu", value: "Bannu" },
  { label: "Batkhela", value: "Batkhela" },
  { label: "Battagram", value: "Battagram" },
  { label: "Birote", value: "Birote" },
  { label: "Chakdara", value: "Chakdara" },
  { label: "Charsadda", value: "Charsadda" },
  { label: "Chitral", value: "Chitral" },
  { label: "Daggar", value: "Daggar" },
  { label: "Dargai", value: "Dargai" },
  { label: "Darya Khan", value: "Darya Khan" },
  { label: "Dera Ismail", value: "Dera Ismail" },
  { label: "Doaba", value: "Doaba" },
  { label: "Dir", value: "Dir" },
  { label: "Drosh", value: "Drosh" },
  { label: "Hangu", value: "Hangu" },
  { label: "Haripur", value: "Haripur" },
  { label: "Karak", value: "Karak" },
  { label: "Kohat", value: "Kohat" },
  { label: "Kulachi", value: "Kulachi" },
  { label: "Lakki Marwat", value: "Lakki Marwat" },
  { label: "Latamber", value: "Latamber" },
  { label: "Madyan", value: "Madyan" },
  { label: "Mansehra", value: "Mansehra" },
  { label: "Mardan", value: "Mardan" },
  { label: "Mastuj", value: "Mastuj" },
  { label: "Mingora", value: "Mingora" },
  { label: "Nowshera", value: "Nowshera" },
  { label: "Paharpur", value: "Paharpur" },
  { label: "Pabbi", value: "Pabbi" },
  { label: "Peshawar", value: "Peshawar" },
  { label: "Saidu Sharif", value: "Saidu Sharif" },
  { label: "Shorkot", value: "Shorkot" },
  { label: "Shewa Adda", value: "Shewa Adda" },
  { label: "Swabi", value: "Swabi" },
  { label: "Swat", value: "Swat" },
  { label: "Tangi", value: "Tangi" },
  { label: "Tank", value: "Tank" },
  { label: "Thall", value: "Thall" },
  { label: "Timergara", value: "Timergara" },
  { label: "Tordher", value: "Tordher" },
  { label: "Awaran", value: "Awaran" },
  { label: "Barkhan", value: "Barkhan" },
  { label: "Chagai", value: "Chagai" },
  { label: "Dera Bugti", value: "Dera Bugti" },
  { label: "Gwadar", value: "Gwadar" },
  { label: "Harnai", value: "Harnai" },
  { label: "Jafarabad", value: "Jafarabad" },
  { label: "Jhal Magsi", value: "Jhal Magsi" },
  { label: "Kacchi", value: "Kacchi" },
  { label: "Kalat", value: "Kalat" },
  { label: "Kech", value: "Kech" },
  { label: "Kharan", value: "Kharan" },
  { label: "Khuzdar", value: "Khuzdar" },
  { label: "Killa Abdullah", value: "Killa Abdullah" },
  { label: "Killa Saifullah", value: "Killa Saifullah" },
  { label: "Kohlu", value: "Kohlu" },
  { label: "Lasbela", value: "Lasbela" },
  { label: "Lehri", value: "Lehri" },
  { label: "Loralai", value: "Loralai" },
  { label: "Mastung", value: "Mastung" },
  { label: "Musakhel", value: "Musakhel" },
  { label: "Nasirabad", value: "Nasirabad" },
  { label: "Nushki", value: "Nushki" },
  { label: "Panjgur", value: "Panjgur" },
  { label: "Pishin valley", value: "Pishin valley" },
  { label: "Quetta", value: "Quetta" },
  { label: "Sherani", value: "Sherani" },
  { label: "Sibi", value: "Sibi" },
  { label: "Sohbatpur", value: "Sohbatpur" },
  { label: "Washuk", value: "Washuk" },
  { label: "Zhob", value: "Zhob" },
  { label: "Ziarat", value: "Ziarat" },
];

export const tehsilsOption = [
  { label: "Islamabad", value: "Islamabad" },
  { label: "Ahmed Nager Chatha", value: "Ahmed Nager Chatha" },
  { label: "Ahmedpur East", value: "Ahmedpur East" },
  { label: "Ali Khan Abad", value: "Ali Khan Abad" },
  { label: "Alipur", value: "Alipur" },
  { label: "Arifwala", value: "Arifwala" },
  { label: "Attock", value: "Attock" },
  { label: "Bhera", value: "Bhera" },
  { label: "Bhalwal", value: "Bhalwal" },
  { label: "Bahawalnagar", value: "Bahawalnagar" },
  { label: "Bahawalpur", value: "Bahawalpur" },
  { label: "Bhakkar", value: "Bhakkar" },
  { label: "Burewala", value: "Burewala" },
  { label: "Chillianwala", value: "Chillianwala" },
  { label: "Chakwal", value: "Chakwal" },
  { label: "Chichawatni", value: "Chichawatni" },
  { label: "Chiniot", value: "Chiniot" },
  { label: "Chishtian", value: "Chishtian" },
  { label: "Daska", value: "Daska" },
  { label: "Darya Khan", value: "Darya Khan" },
  { label: "Dera Ghazi Khan", value: "Dera Ghazi Khan" },
  { label: "Dhaular", value: "Dhaular" },
  { label: "Dina", value: "Dina" },
  { label: "Dinga", value: "Dinga" },
  { label: "Dipalpur", value: "Dipalpur" },
  { label: "Faisalabad", value: "Faisalabad" },
  { label: "Fateh Jang", value: "Fateh Jang" },
  { label: "Ghakhar Mandi", value: "Ghakhar Mandi" },
  { label: "Gojra", value: "Gojra" },
  { label: "Gujranwala", value: "Gujranwala" },
  { label: "Gujrat", value: "Gujrat" },
  { label: "Gujar Khan", value: "Gujar Khan" },
  { label: "Hafizabad", value: "Hafizabad" },
  { label: "Haroonabad", value: "Haroonabad" },
  { label: "Hasilpur", value: "Hasilpur" },
  { label: "Haveli Lakha", value: "Haveli Lakha" },
  { label: "Jalalpur Jattan", value: "Jalalpur Jattan" },
  { label: "Jampur", value: "Jampur" },
  { label: "Jaranwala", value: "Jaranwala" },
  { label: "Jhang", value: "Jhang" },
  { label: "Jhelum", value: "Jhelum" },
  { label: "Kalabagh", value: "Kalabagh" },
  { label: "Karor Lal", value: "Karor Lal" },
  { label: "Kasur", value: "Kasur" },
  { label: "Kamalia", value: "Kamalia" },
  { label: "Kamoke", value: "Kamoke" },
  { label: "Khanewal", value: "Khanewal" },
  { label: "Khanpur", value: "Khanpur" },
  { label: "Kharian", value: "Kharian" },
  { label: "Khushab", value: "Khushab" },
  { label: "Kot Adu", value: "Kot Adu" },
  { label: "Jauharabad", value: "Jauharabad" },
  { label: "Lahore", value: "Lahore" },
  { label: "Lalamusa", value: "Lalamusa" },
  { label: "Layyah", value: "Layyah" },
  { label: "Liaquat Pur", value: "Liaquat Pur" },
  { label: "Lodhran", value: "Lodhran" },
  { label: "Malakwal", value: "Malakwal" },
  { label: "Mamoori", value: "Mamoori" },
  { label: "Mailsi", value: "Mailsi" },
  { label: "Mandi Bahauddin", value: "Mandi Bahauddin" },
  { label: "Mian Channu", value: "Mian Channu" },
  { label: "Mianwali", value: "Mianwali" },
  { label: "Multan", value: "Multan" },
  { label: "Murree", value: "Murree" },
  { label: "Muridke", value: "Muridke" },
  { label: "Mianwali Bangla", value: "Mianwali Bangla" },
  { label: "Muzaffargarh", value: "Muzaffargarh" },
  { label: "Narowal", value: "Narowal" },
  { label: "Okara", value: "Okara" },
  { label: "Renala Khurd", value: "Renala Khurd" },
  { label: "Pakpattan", value: "Pakpattan" },
  { label: "Pattoki", value: "Pattoki" },
  { label: "Pir Mahal", value: "Pir Mahal" },
  { label: "Qaimpur", value: "Qaimpur" },
  { label: "Qila Didar Singh", value: "Qila Didar Singh" },
  { label: "Rabwah", value: "Rabwah" },
  { label: "Raiwind", value: "Raiwind" },
  { label: "Rajanpur", value: "Rajanpur" },
  { label: "Rahim Yar Khan", value: "Rahim Yar Khan" },
  { label: "Rawalpindi", value: "Rawalpindi" },
  { label: "Sadiqabad", value: "Sadiqabad" },
  { label: "Safdarabad", value: "Safdarabad" },
  { label: "Sahiwal", value: "Sahiwal" },
  { label: "Sangla Hill", value: "Sangla Hill" },
  { label: "Sarai Alamgir", value: "Sarai Alamgir" },
  { label: "Sargodha", value: "Sargodha" },
  { label: "Shakargarh", value: "Shakargarh" },
  { label: "Sheikhupura", value: "Sheikhupura" },
  { label: "Sialkot", value: "Sialkot" },
  { label: "Sohawa", value: "Sohawa" },
  { label: "Soianwala", value: "Soianwala" },
  { label: "Siranwali", value: "Siranwali" },
  { label: "Talagang", value: "Talagang" },
  { label: "Taxila", value: "Taxila" },
  { label: "Toba Tek Singh", value: "Toba Tek Singh" },
  { label: "Vehari", value: "Vehari" },
  { label: "Wah Cantonment", value: "Wah Cantonment" },
  { label: "Wazirabad", value: "Wazirabad" },
  { label: "Badin", value: "Badin" },
  { label: "Bhirkan", value: "Bhirkan" },
  { label: "Rajo Khanani", value: "Rajo Khanani" },
  { label: "Chak", value: "Chak" },
  { label: "Dadu", value: "Dadu" },
  { label: "Digri", value: "Digri" },
  { label: "Diplo", value: "Diplo" },
  { label: "Dokri", value: "Dokri" },
  { label: "Ghotki", value: "Ghotki" },
  { label: "Haala", value: "Haala" },
  { label: "Hyderabad", value: "Hyderabad" },
  { label: "Islamkot", value: "Islamkot" },
  { label: "Jacobabad", value: "Jacobabad" },
  { label: "Jamshoro", value: "Jamshoro" },
  { label: "Jungshahi", value: "Jungshahi" },
  { label: "Kandhkot", value: "Kandhkot" },
  { label: "Kandiaro", value: "Kandiaro" },
  { label: "Karachi", value: "Karachi" },
  { label: "Kashmore", value: "Kashmore" },
  { label: "Keti Bandar", value: "Keti Bandar" },
  { label: "Khairpur", value: "Khairpur" },
  { label: "Kotri", value: "Kotri" },
  { label: "Larkana", value: "Larkana" },
  { label: "Matiari", value: "Matiari" },
  { label: "Mehar", value: "Mehar" },
  { label: "Mirpur Khas", value: "Mirpur Khas" },
  { label: "Mithani", value: "Mithani" },
  { label: "Mithi", value: "Mithi" },
  { label: "Mehrabpur", value: "Mehrabpur" },
  { label: "Moro", value: "Moro" },
  { label: "Nagarparkar", value: "Nagarparkar" },
  { label: "Naudero", value: "Naudero" },
  { label: "Naushahro Feroze", value: "Naushahro Feroze" },
  { label: "Naushara", value: "Naushara" },
  { label: "Nawabshah", value: "Nawabshah" },
  { label: "Nazimabad", value: "Nazimabad" },
  { label: "Qambar", value: "Qambar" },
  { label: "Qasimabad", value: "Qasimabad" },
  { label: "Ranipur", value: "Ranipur" },
  { label: "Ratodero", value: "Ratodero" },
  { label: "Rohri", value: "Rohri" },
  { label: "Sakrand", value: "Sakrand" },
  { label: "Sanghar", value: "Sanghar" },
  { label: "Shahbandar", value: "Shahbandar" },
  { label: "Shahdadkot", value: "Shahdadkot" },
  { label: "Shahdadpur", value: "Shahdadpur" },
  { label: "Shahpur Chakar", value: "Shahpur Chakar" },
  { label: "Shikarpaur", value: "Shikarpaur" },
  { label: "Sukkur", value: "Sukkur" },
  { label: "Tangwani", value: "Tangwani" },
  { label: "Tando Adam", value: "Tando Adam" },
  { label: "Tando Allahyar", value: "Tando Allahyar" },
  { label: "Tando Muhammad Khan", value: "Tando Muhammad Khan" },
  { label: "Thatta", value: "Thatta" },
  { label: "Umerkot", value: "Umerkot" },
  { label: "Warah", value: "Warah" },
  { label: "Abbottabad", value: "Abbottabad" },
  { label: "Adezai", value: "Adezai" },
  { label: "Alpuri", value: "Alpuri" },
  { label: "Akora Khattak", value: "Akora Khattak" },
  { label: "Ayubia", value: "Ayubia" },
  { label: "Banda Daud Shah", value: "Banda Daud Shah" },
  { label: "Bannu", value: "Bannu" },
  { label: "Batkhela", value: "Batkhela" },
  { label: "Battagram", value: "Battagram" },
  { label: "Birote", value: "Birote" },
  { label: "Chakdara", value: "Chakdara" },
  { label: "Charsadda", value: "Charsadda" },
  { label: "Chitral", value: "Chitral" },
  { label: "Daggar", value: "Daggar" },
  { label: "Dargai", value: "Dargai" },
  { label: "Darya Khan", value: "Darya Khan" },
  { label: "Dera Ismail Khan", value: "Dera Ismail Khan" },
  { label: "Doaba", value: "Doaba" },
  { label: "Dir", value: "Dir" },
  { label: "Drosh", value: "Drosh" },
  { label: "Hangu", value: "Hangu" },
  { label: "Haripur", value: "Haripur" },
  { label: "Karak", value: "Karak" },
  { label: "Kohat", value: "Kohat" },
  { label: "Kulachi", value: "Kulachi" },
  { label: "Lakki Marwat", value: "Lakki Marwat" },
  { label: "Latamber", value: "Latamber" },
  { label: "Madyan", value: "Madyan" },
  { label: "Mansehra", value: "Mansehra" },
  { label: "Mardan", value: "Mardan" },
  { label: "Mastuj", value: "Mastuj" },
  { label: "Mingora", value: "Mingora" },
  { label: "Nowshera", value: "Nowshera" },
  { label: "Paharpur", value: "Paharpur" },
  { label: "Pabbi", value: "Pabbi" },
  { label: "Peshawar", value: "Peshawar" },
  { label: "Saidu Sharif", value: "Saidu Sharif" },
  { label: "Shorkot", value: "Shorkot" },
  { label: "Shewa Adda", value: "Shewa Adda" },
  { label: "Swabi", value: "Swabi" },
  { label: "Swat", value: "Swat" },
  { label: "Tangi", value: "Tangi" },
  { label: "Tank", value: "Tank" },
  { label: "Thall", value: "Thall" },
  { label: "Timergara", value: "Timergara" },
  { label: "Tordher", value: "Tordher" },
  { label: "Awaran", value: "Awaran" },
  { label: "Barkhan", value: "Barkhan" },
  { label: "Chagai", value: "Chagai" },
  { label: "Dera Bugti", value: "Dera Bugti" },
  { label: "Gwadar", value: "Gwadar" },
  { label: "Harnai", value: "Harnai" },
  { label: "Jafarabad", value: "Jafarabad" },
  { label: "Jhal Magsi", value: "Jhal Magsi" },
  { label: "Kalat", value: "Kalat" },
  { label: "Kech", value: "Kech" },
  { label: "Kharan", value: "Kharan" },
  { label: "Khuzdar", value: "Khuzdar" },
  { label: "Killa Abdullah", value: "Killa Abdullah" },
  { label: "Killa Saifullah", value: "Killa Saifullah" },
  { label: "Kohlu", value: "Kohlu" },
  { label: "Lasbela", value: "Lasbela" },
  { label: "Lehri", value: "Lehri" },
  { label: "Loralai", value: "Loralai" },
  { label: "Mastung", value: "Mastung" },
  { label: "Musakhel", value: "Musakhel" },
  { label: "Nasirabad", value: "Nasirabad" },
  { label: "Nushki", value: "Nushki" },
  { label: "Panjgur", value: "Panjgur" },
  { label: "Pishin", value: "Pishin" },
  { label: "Quetta", value: "Quetta" },
  { label: "Sherani", value: "Sherani" },
  { label: "Sibi", value: "Sibi" },
  { label: "Sohbatpur", value: "Sohbatpur" },
  { label: "Washuk", value: "Washuk" },
  { label: "Zhob", value: "Zhob" },
  { label: "Ziarat", value: "Ziarat" },
  { label: "Astore", value: "Astore" },
  { label: "Diamer", value: "Diamer" },
  { label: "Ghanche", value: "Ghanche" },
  { label: "Gilgit", value: "Gilgit" },
  { label: "Hunza", value: "Hunza" },
  { label: "Kharmang", value: "Kharmang" },
  { label: "Nagar", value: "Nagar" },
  { label: "Shigar", value: "Shigar" },
  { label: "Shangla", value: "Shangla" },
  { label: "Skardu", value: "Skardu" },
  { label: "Bagh", value: "Bagh" },
  { label: "Bhimber", value: "Bhimber" },
  { label: "Hattian", value: "Hattian" },
  { label: "Haveli", value: "Haveli" },
  { label: "Kotli", value: "Kotli" },
  { label: "Mirpur", value: "Mirpur" },
  { label: "Muzaffarabad", value: "Muzaffarabad" },
  { label: "Neelum", value: "Neelum" },
  { label: "Poonch", value: "Poonch" },
  { label: "Sudhanoti", value: "Sudhanoti" },
];

export const productCategory = {
  "Crop Nutritions": [
    "Amino Acids",
    "Gibberellic Acids",
    "Micronutrients",
    "PGPR's (Plant Growth-Promoting Rhizobacteria)",
    "Plant Growth Regulaters",
  ],
  Fertilizers: [
    "Anti Stress Fertilizers",
    "Basic Fertilizers",
    "Bio Fertilizers (Organic Fertilizers)",
    "Growth Stimulaters",
    "Imported Fertilizers",
    "Liquid Fertilizers",
    "Organic Fertilizers",
    "Soil Conditioners",
  ],
  Pesticides: [
    "Adjuvants",
    "Fumigants",
    "Fungicides",
    "Fungicides And Bactericides",
    "Granules Insecticides (Poisions)",
    "Herbicides / Weedicides",
    "Insecticides",
    "Seed Treatments",
  ],
};
